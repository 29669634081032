import axios from 'axios';
import config from '../config.js';

class PedidoService {
  async createPedido(
    clienteId,
    agenteId,
    pedidos,
    envios,
    observaciones,
    descuento,
    lugar,
    cve_suc
  ) {
    observaciones = observaciones.split('\n').join(' ');

    let response = {};

    try {
      let data = await axios.post(`${config.apiUrl}create/pedido/`, {
        data: {
          cve_cte: clienteId || '10828',
          cve_age: agenteId,
          cve_suc: cve_suc,
          lugar: lugar,
          observa: observaciones,
          descuento,
          pedidos,
          envios,
        },
      });

      response = data.data.data;
    } catch (error) {
      response = { status: -1 };
    }

    return response;
  }

  async getPedido(id) {
    let result;
    try {
      result = await axios.get(`${config.apiUrl}pedidoc/${id}`);
      result = result.data.data;
    } catch (error) {
      result = null;
    } finally {
      return result;
    }
  }

  async getDetallePedido(numPedido) {
    let result;
    try {
      result = await axios.get(`${config.apiUrl}pedido/detalle/${numPedido}`);
      let resultCode = result.data.data.status;
      if (resultCode === 200) {
        result = result.data.data.result;

        if (result.length > 0) {
          let newResult = [];
          result = result.map((data) => ({
            ...data,
            cantidad: String(parseInt(data.cantidad)),
          }));
          for (let i = 0; i < result.length; i++) {
            let dataDetalle = await axios.get(
              `${config.apiUrl}pedido/detalle2?cse_prod=${result[i].cse_prod}&cve_prod=${result[i].cve_prod}&new_med=${result[i].new_med}`
            );
            if (dataDetalle.data.data.status === 200) {
              dataDetalle = dataDetalle.data.data.result;
              newResult.push(dataDetalle[0]);
              newResult[i].cantidad = result[i].cantidad;
              newResult[i].codigo = result[i].codigo;
              newResult[i].cse_prod = result[i].cse_prod;
              newResult[i].cve_prod = result[i].cve_prod;
              newResult[i].new_med = result[i].new_med;
              let _descripcion = String(newResult[i].desc_prod).split(' ');
              _descripcion.splice(-1, 1);
              newResult[i].descripcion = _descripcion.join(' ');
              newResult[i].tipo = 'articulo';
            }
          }
          result = newResult;
        }
      } else {
        result = [];
      }
    } catch (exception) {
      result = [];
    } finally {
      return result;
    }
  }

  async cancelPedido(id) {
    let result;
    try {
      await axios
        .post(`${config.apiUrl}cancel/pedido?id=${id}`)
        .then(() => (result = true))
        .catch(() => (result = false));
    } catch (error) {
      result = false;
    } finally {
      return result;
    }
  }

  async pedidoInfo(numero) {
    let result;
    try {
      result = await axios.get(`${config.apiUrl}pedido/info?numero=${numero}`);
      if (result.data.status === 200) {
        result = result.data.result;
      } else {
        result = [];
      }
    } catch (e) {
      result = [];
      console.log(e);
    } finally {
      return result;
    }
  }
  async pedidoInfoDetalle(numero) {
    let result;
    try {
      result = await axios.get(
        `${config.apiUrl}pedido/info/detalle?numero=${numero}`
      );
      if (result.data.status === 200) {
        result = {
          result: result.data.result,
          remision: result.data.remision,
        };
      } else {
        result = [];
      }
    } catch (e) {
      result = [];
      console.log(e);
    } finally {
      return result;
    }
  }
}

export { PedidoService as default };
