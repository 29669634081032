import { Button, Col, Container, Row, TextInput } from 'react-materialize';

import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import ModalInvoiceItem from './modal-invoice.view';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';
import InvoiceReprintView from './../modal-reporte/pedido-reprint';
import printJS from 'print-js';

// servicios

class ModalInvoices extends React.Component {
  state = {
    loading: false,
    numInvoice: '',
    itemsInvoice: null,
    btnBuscarEstado: false,
    lastSearch: ''
  };

  async componentDidMount() {
    this.setState({
      numInvoice: '',
    });
  }

  handleChange = (e) => {
    this.setState({
      numInvoice: e.target.value,
    });
  };

  handleSearchInvoice = async () => {
    let numInvoice = this.state.numInvoice.trim();
    if (this.state.lastSearch === numInvoice) 
    {
      M.toast({
        html: 'Es la misma Factura',
        classes: 'message-success',
      });
      return;
    }
    this.setState({
      loading: true,
      lastSearch: numInvoice
    });
    try {
      let result = await qad.catalog.salesOrder.findInvoiceById(numInvoice);
      console.log(result);
      this.setState({
        itemsInvoice: [result]
      });
      if (!result) {
        M.toast({ html: 'No se encontro numero de Factura' });
        this.setState({
          itemsInvoice: null,
        });
      }
    } catch (error) {
      this.setState({
        itemsInvoice: null,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      numInvoice: null,
      itemsInvoice: null,
      itemsInvoiceDet: null,
      lastSearch: ''
    });
    this.props.onClose();
  };
  // muestra Factura
  showInvoice = () => {
    if (this.state.itemsInvoice && this.state.itemsInvoice.length > 0) {
      return (
        <ModalInvoiceItem
          item={this.state.itemsInvoice}
          onCancel={this.handleCancelarInvoice}
          onGenerar={this.handleGenerarInvoice}
          print={this.print}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.handleCloseModal}
        >
          <Container>
            <Row>
              <Col s={12} m={12} l={12} xl={12}>
                <h3 className="m3">Factura</h3>
                <TextInput
                  id="numInvoiceInput"
                  s={12}
                  m={12}
                  l={12}
                  xl={12}
                  value={this.state.numInvoice || ''}
                  onChange={this.handleChange}
                  label="Numero de Factura"
                />
                <Button
                  className="m3"
                  node="button"
                  waves="light"
                  disabled={this.state.btnBuscarEstado}
                  onClick={this.handleSearchInvoice}
                >
                  Buscar Factura
                </Button>
              </Col>
            </Row>
            <br></br>
            {this.state.loading ? <LoadingView /> : this.showInvoice()}
          </Container>
        </Modal>
      </React.Fragment>
    );
  }

  handleCancelarInvoice = async () => {
    let result;
    try {
      this.setState({
        btnBuscarEstado: true,
      });

      // modal loading
      Swal.fire({
        title: 'Cancelando',
        html: 'Procesando cancelaci&oacuten..',
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if ( !this.state.numInvoice ) {
        throw Error();
      }
      result = await  await qad.catalog.salesOrder.cancelInvoice(this.state.numInvoice);
    } catch (error) {
      M.toast({
        html: 'Ocurrio un error al cancelar',
        classes: 'message-error',
      });
    } finally {
      this.setState({
        btnBuscarEstado: false,
        numInvoice: null
      });
      Swal.close();
      return result;
    }
  };
}
export { ModalInvoices as default };
