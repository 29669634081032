import BrandService from '../services/brand.service.js';
import CategoryService from '../services/category.service.js';
import colorService from '../services/color.service.js';
import CustomerService from '../services/customer.service.js';
import GenderService from '../services/gender.service.js';
import InventoryService from '../services/inventory.service.js';
import LocationService from '../services/location.service.js';
import PantoneService from '../services/pantone.service.js';
import ParcelService from '../services/parcel.service.js';
import PatternService from '../services/pattern.service.js';
import PriceListService from '../services/price-list.service.js';
import ProductService from '../services/product.service.js';
import SalesOrderService from '../services/sales-order.service.js';
import SalesPersonService from '../services/sales-person.service.js';
import SizeService from '../services/size.service.js';
import StyleService from '../services/style.service.js';
import SubcategoryService from '../services/subcategory.service.js';
import WebsiteService from '../services/website.service.js';

class CatalogController {
  constructor(client) {
    this.brand = new BrandService(client);
    this.category = new CategoryService(client);
    this.client = client;
    this.color = new colorService(client);
    this.customer = new CustomerService(client);
    this.gender = new GenderService(client);
    this.inventory = new InventoryService(client);
    this.location = new LocationService(client);
    this.pantone = new PantoneService(client);
    this.parcel = new ParcelService(client);
    this.pattern = new PatternService(client);
    this.priceList = new PriceListService(client);
    this.product = new ProductService(client);
    this.salesOrder = new SalesOrderService(client);
    this.salesPerson = new SalesPersonService(client);
    this.size = new SizeService(client);
    this.style = new StyleService(client);
    this.subcategory = new SubcategoryService(client);
    this.website = new WebsiteService(client);
  }
}

export { CatalogController as default };
