import React from 'react';
import './grid-reporte.css';
import { Grid, GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from 'react-materialize';


class GridReporte extends React.Component {

    _export;
    export = () => {
        this._export.save(this.props.data);
    }

    componentDidMount() {
        // console.log(this.props);
    }

    render() {

        return(<React.Fragment>
            <ExcelExport ref={exporter => this._export = exporter}>
                <Grid
                    data={this.props.data}
                    resizable>
                    <GridNoRecords>
                        No se encontraron registros
                    </GridNoRecords>
                    <GridToolbar>
                        <div className="toolbar">
                            <Button
                            node="button"
                            onClick={this.export}><span className="k-icon k-i-excel"></span> Exportar a Excel</Button>
                            <section>
                                {(this.props.filtros === 0)
                                ?
                                <p>Ultimos <strong>{this.props.data.length}</strong> registros</p>
                                :
                                <p>Registros encontrados: <strong>{this.props.data.length}</strong></p>
                                }
                            </section>
                        </div>
                    </GridToolbar>
                    <GridColumn field="cve_canal" title="Canal"></GridColumn>
                    <GridColumn field="cve_agente" title="Agente"></GridColumn>
                    <GridColumn field="clase" title="Clase"></GridColumn>
                    <GridColumn field="cantidad_unid" title="Cantidad Unid."></GridColumn>
                    <GridColumn field="monto_total" title="Monto Total"></GridColumn>
                </Grid>
            </ExcelExport>
        </React.Fragment>);
    }

}
export default GridReporte;