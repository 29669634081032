import React from 'react'
import { Row, Col, Preloader } from 'react-materialize'

import './loading.css'

const LoadingView = (props) => {
    return (
        <Row className='margin-top-50'>
            <Col xl={3} l={3} m={3} s={3} className='center'>
            </Col>
            <Col xl={5} l={5} m={5} s={5}  className='center'>
                <Preloader flashing size='big' />
            </Col>
            <Col xl={3} l={3} m={3} s={3}  className='center'>
            </Col>
        </Row>
    )
}

export { LoadingView as default }
