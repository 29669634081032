import './modal-pedidos.css';

import { Button, Col, Row } from 'react-materialize';

import React from 'react';
import Swal from 'sweetalert2';

function ModalRemisionItem(props) {
  let items = props.items;
  let cancelAuth = JSON.parse(localStorage.getItem('user')).orderCanc;
  return (
    <React.Fragment>
      {items.map((item, index) => {
        return (
          <React.Fragment key={item.remisionId}>
            <div className="containerPedidoItem">
              <Row>
                <Col s={7} m={7} l={7} xl={7}>
                  <p>
                    <strong># Pedido:</strong> {item.salesOrderId}
                  </p>
                  <p>
                    <strong>Cliente:</strong> {item.customer}
                  </p>
                </Col>
                <Col s={5} m={5} l={5} xl={5}>
                  <p>
                    <strong>Vendedor:</strong>{' '}
                    {item.salesPerson ? item.salesPerson: ""}
                  </p>
                </Col>
                <Col s={5} m={5} l={5} xl={5}>
                  <p>
                    <strong>Vendedor:</strong>{' '}
                    {item.invoiceId ? item.invoiceId: ""}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col s={12} m={12} l={12} xl={12} align="center">
                  {cancelAuth === "1" &&
                    (item.status === 'Cancelado' || item.invoiceId !== "" ? null : (
                      <Button
                        node="button"
                        waves="light"
                        style={{ margin: '0 1%' }}
                        onClick={() => {
                          Swal.fire({
                            title: '¿Estas seguro?',
                            text: 'Vas a cancelar el pedido..',
                            icon: 'warning',
                            reverseButtons: true,
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Aceptar',
                          }).then(async (result) => {
                            if (result.value) {
                              let resultCancel = await props.onCancel();
                              if (resultCancel) {
                                Swal.fire(
                                  'Cancelado!',
                                  'Pedido cancelado.',
                                  'success'
                                );
                              }
                            }
                          });
                        }}
                      >
                        Cancelar pedido
                      </Button>
                    ))}
                  <Button
                    node="button"
                    waves="light"
                    style={{ margin: '0 1%' }}
                    onClick={ () => {
                      props.print(item.salesOrderId)
                    }}
                  >
                    Imprimir
                  </Button>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
export { ModalRemisionItem as default };
