import GridReporte from './grid-reporte';
import Modal from '../modals/modal';
import React from 'react';
import ReporteFiltros from './modal-reporte-filtros';
import ReporteService from '../../../services/reporte.service';
import Swal from 'sweetalert2';

// services


class ModalReporte extends React.Component {
  state = {
    dataGrid: [],
    fecha1: null,
    fecha2: null,
    loading: false,
  };

  // servicios obj
  reporteService = new ReporteService();

  async componentDidMount() {}

  handleClose = () => {
    this.setState({
      dataGrid: [],
    });
    this.props.onClose();
  };

  handleBuscar = async () => {
    // ejecucion de busqueda
    this.setState({
      loading: true,
    });

    await this.handleObtenerFechas();

    let datos = [];

    if (this.state.fecha1 && this.state.fecha2) {
      try {
        datos = await this.reporteService.getReporteCanalAgentaClase(
          this.state.fecha1,
          this.state.fecha2
        );
        let nuevos_datos = [];
        // filtrar resultados
        if (typeof datos == 'object') {
          if (datos.length > 0) {
            for (let i = 0; i < datos.length; i++) {
              let canal = datos[i].cve_canal;
              let agente = datos[i].cve_agente;
              let clase = datos[i].clase;
              if (i !== datos.length - 1) {
                if (datos[i + 1].clase !== clase) {
                  nuevos_datos.push(datos[i]);
                  let filter_data = datos.filter(
                    (response) =>
                      response.cve_canal === canal &&
                      response.cve_agente === agente &&
                      response.clase === clase
                  );
                  let cant = 0;
                  let monto = 0;
                  for (let x = 0; x < filter_data.length; x++) {
                    cant += parseFloat(filter_data[x].cantidad_unid);
                    monto += parseFloat(filter_data[x].monto_total);
                  }
                  nuevos_datos[nuevos_datos.length - 1].cantidad_unid = cant;
                  nuevos_datos[nuevos_datos.length - 1].monto_total =
                    parseFloat(monto).toFixed(2);
                }
              } else {
                let eval_nuevos_datos = nuevos_datos.filter(
                  (response) =>
                    response.cve_canal === canal &&
                    response.cve_agente === agente &&
                    response.clase === clase
                );
                if (eval_nuevos_datos.length === 0) {
                  let filter_data = datos.filter(
                    (response) =>
                      response.cve_canal === canal &&
                      response.cve_agente === agente &&
                      response.clase === clase
                  );
                  if (filter_data.length > 0) {
                    nuevos_datos.push(datos[i]);
                    let cant = 0;
                    let monto = 0;
                    for (let x = 0; x < filter_data.length; x++) {
                      cant += parseFloat(filter_data[x].cantidad_unid);
                      monto += parseFloat(filter_data[x].monto_total);
                    }
                    nuevos_datos[nuevos_datos.length - 1].cantidad_unid = cant;
                    nuevos_datos[nuevos_datos.length - 1].monto_total =
                      parseFloat(monto).toFixed(2);
                  }
                }
              }
            }
            nuevos_datos = nuevos_datos.map((response) => ({
              ...response,
              cve_agente: `${response.cve_agente} ${response.agente}`,
              cve_canal: `${response.cve_canal} ${response.canal}`,
              monto_total: parseFloat(response.monto_total),
            }));
            datos = nuevos_datos;
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Favor de llenar todos los campos',
      });
    }

    this.setState({
      dataGrid: datos,
      loading: false,
    });
  };

  handleObtenerFechas = () => {
    return new Promise((resolve) => {
      this.setState({
        fecha1: document.getElementById('fecha1').value
          ? document.getElementById('fecha1').value
          : null,
        fecha2: document.getElementById('fecha2').value
          ? document.getElementById('fecha2').value
          : null,
      });
      resolve(true);
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalReporte2IsOpen}
          onClose={() => {
            this.handleClose();
          }}
        >
          <ReporteFiltros
            onChangeFecha1={this.handleChangeFecha1}
            onChangeFecha2={this.handleChangeFecha2}
            onBuscar={this.handleBuscar}
          />
          <br />
          {this.state.loading ? (
            <div className="loader"></div>
          ) : (
            <GridReporte
              data={this.state.dataGrid}
              onBuscar={this.handleBuscar}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
export default ModalReporte;
