import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class GenderService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'gender';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', categoryId: '', brandId: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id,
        categoryId: '',
        brandId: '',
      },
    });
    const [gender] = formatResponse(getResponse);
    return gender;
  }

  /**
   * Returns genders related by passed category identifier and brand identifier
   * @param {Object} params
   * @param {string} params.categoryId
   * @param {string} params.brandId
   * @return {Object[]}
   */
  async relatedBy(params) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const { categoryId = '', brandId = '' } = params;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id: '',
        categoryId,
        brandId,
      },
    });
    return formatResponse(getResponse);
  }
}

export { GenderService as default };
