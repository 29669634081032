import CatalogController from './controllers/catalog-controller.class.js';
import axios from 'axios';

class ErpConnect {
  constructor(apiUrlBase) {
    this.apiUrlBase = apiUrlBase;

    this.client = axios.create({
      baseURL: this.apiUrlBase,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.catalog = new CatalogController(this.client);
  }
}

export { ErpConnect as default };
