import ArticulosService from './articulo.service.js';
import ClienteService from './cliente.service.js';
import EnvioService from './envio.service.js';
import ErpConnectClass from './erpconnect/erp-connect.class.js';
import EstiloService from './estilo.service.js';
import ExistenciaService from './inventario.service.js';
import FamiliaService from './familia.service.js';
import FileService from './file.service.js';
import GeneroService from './genero.service.js';
import LoginService from './login.service.js';
import MarcaService from './marca.service.js';
import ModeloService from './modelo.service.js';
import NavigationService from './navigation.service.js';
import PedidoService from './pedido.service.js';
import ProductoService from './producto.service.js';
import ReporteService from './reporte.service.js';
import erpConfig from '../erpconfig.js';

const ErpConnect = new ErpConnectClass(erpConfig.apiBaseUrl);

export {
  ErpConnect,
  EstiloService,
  FamiliaService,
  GeneroService,
  LoginService,
  MarcaService,
  ModeloService,
  NavigationService,
  ProductoService,
  EnvioService,
  PedidoService,
  ExistenciaService,
  FileService,
  ClienteService,
  ArticulosService,
  ReporteService,
};
