import { Button, Col } from 'react-materialize';

import React from 'react';

function MarcasRealacionadasView(props) {
  let items = props.items;

  if (items) {
    return (
      <div style={{ height: 'auto' }}>
        {items.map((element, index) => {
          return (
            <React.Fragment key={element.modelo}>
              <Col l={3} m={4} s={6} style={{ marginBottom: '3px' }}>
                <Button
                  small={true}
                  style={{
                    width: '95%',
                    minHeight: '40px',
                    lineHeight: '1',
                    fontSize: '10pt',
                    backgroundColor: '#00aaf6',
                  }}
                  align="center"
                  xl={12}
                  l={12}
                  m={12}
                  s={12}
                  node="button"
                  waves="light"
                  onClick={async () => {
                    element.tipo = 'modelo';
                    await props.changeProductViewIds(
                      element.id_categoria,
                      element.nombre_categoria,
                      element.id_marca,
                      element.nombre_marca,
                      element.id_genero,
                      element.nombre_genero,
                      element.id_estilo,
                      element.nombre_estilo,
                      element.modelo
                    );
                    props.onClickElement(element);
                  }}
                >
                  {element.nombre_modelo}
                </Button>
              </Col>
            </React.Fragment>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
}
export default MarcasRealacionadasView;
