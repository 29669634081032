import React from 'react';
import Modal from '../modals/modal';
import ReporteProducts from './modal-products';
import Loader from '../loader/loader.view';
import GridReporteProducts from './grid-reporte';
import { ErpConnect as qad } from '../../../services';

class ModalProducts extends React.Component {
  state = {
    mounted: false,
    loading: true,
    dataGrid: null,
    loadingGrid: false,
    reportePrint: false,
    propsPrint: {},
    busquedaFiltros: false, // valida mensaje en toolbar
  };

  async componentDidMount()
  {
    try {
      this.setState({
        mounted: true,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleClose = () => {
    this.setState({
      dataGrid: [],
    });
    this.props.onClose();
  };

  setSelectValue = (value, category) => {
    let currentState = {};
    currentState[category] = value;
    this.setState(currentState);
  };

  handleBuscar = async () => {
    // ejecucion de busqueda
    this.setState({
      loadingGrid: true,
    });
    const dataGrid = await qad.catalog.product.relatedBy({
      brandId: this.state.brand?.value,
      categoryId: this.state.category?.value,
      genderId: this.state.gender?.value,
      styleId: this.state.style?.value,
      patternId: this.state.pattern?.value 
    });
    let categories = {};
    this.props.catalog.categories.map( (i) => categories[i.id] = i.name );
    let brands = {};
    this.props.catalog.brands.map( (i) => brands[i.id] = i.name );
    let genders = {};
    this.props.catalog.genders.map( (i) => genders[i.id] = i.name );
    let styles = {};
    this.props.catalog.styles.map( (i) => styles[i.id] = i.name );
    let patterns = {};
    this.props.catalog.patterns.map( (i) => patterns[i.id] = i.name );
    let pantones = {};
    this.props.catalog.pantones.map( (i) => pantones[i.id] = i );
    const url = this.props.url;
    const urlPass = this.props.urlPass;
    dataGrid.map( (item, idx) => {
      item.categoryField = categories[item.categoryId];
      item.brandField = brands[item.brandId];
      item.genderField = genders[item.genderId];
      item.styleField = styles[item.styleId];
      item.modelField = patterns[item.modelId];
      const iRoute = [ "categoryField", "brandField", "genderField", "styleField", "modelField"];
      let route = "";
      iRoute.forEach(function(element){
        if (element !== "")
        {
          if(item[element])
          {
            route += "%2F"+item[element].replaceAll(" ", "-").toUpperCase();
          }  
        }
      });
      item.imageName = `${item.id}.png`;
      item.fullImage = `${url}${route}%2F${item.id}.png${urlPass}`;
      let pantonesJSON = [];
      try
      {
        pantonesJSON = JSON.parse(item.colorPantone);
      }
      catch( ex )
      {
        console.log(ex);
      }
      item.pantones = pantonesJSON.map( (pt) => pantones[pt] );
      return item;
    });
    this.setState({
      dataGrid: dataGrid,
      loadingGrid: false,
    });
  };

  render() {
    if (!this.state.loading) {
      if (this.state.reportePrint) {
        return (
          <React.Fragment>
            <Modal
              isOpen={this.props.modalArticulosIsOpen}
              onClose={() => {
                this.handleClose();
              }}
            >
            </Modal>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Modal
              isOpen={this.props.modalArticulosIsOpen}
              onClose={() => {
                this.handleClose();
              }}
            >
              <ReporteProducts
                catalog={this.props.catalog}
                setSelectValue={this.setSelectValue}
                onBuscar={this.handleBuscar}
              />
              <br />
              {this.state.loadingGrid ? (
                <Loader />
              ) : (
                this.state.dataGrid && (
                  <GridReporteProducts
                    catalog={this.props.catalog}
                    data={this.state.dataGrid}
                    onPrint={this.handlePrint}
                    onBuscar={this.handleBuscar}
                    filtros={this.state.busquedaFiltros}
                    url={this.props.url}
                    urlPass={this.props.urlPass}
                  />
                )
              )}
            </Modal>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <Loader />
        </React.Fragment>
      );
    }
  }
}
export default ModalProducts;
