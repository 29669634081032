import { formatResponse, get, post, put, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class CategoryService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'category';
  }

  async all() {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async create(data) {
    const action = 'create';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const postResponse = await post(this.client, endpoint, formattedData);
    const [categoryCreated] = formatResponse(postResponse);
    return categoryCreated;
  }

  async findById(id) {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id },
    });
    const [category] = formatResponse(getResponse);
    return category;
  }

  async update(id, data) {
    const action = 'update';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const putResponse = await put(this.client, endpoint, formattedData, {
      params: { id },
    });
    const [categoryUpdated] = formatResponse(putResponse);
    return categoryUpdated;
  }
}

export { CategoryService as default };
