import React from 'react'
// import Keyboard from 'react-simple-keyboard'
// import {Row, Col} from 'react-bootstrap'
import {Row, Col, TextInput, Button, Icon, Preloader } from 'react-materialize'

import './login.css'
import logo from '../../../assets/logos/logo.png'

const LoginView = (props) => {
    return (
        <Row>
            <Col s={0} m={2} l={3} xl={4}>
            </Col>
            <Col s={12} m={8} l={6} xl={4}>                
                <form onSubmit={props.login}>
                    <div className='login'>
                        <div className='login-info'>
                            <div className='login-logo'>
                                <img src={logo} alt='Logo'/>
                            </div>
                            <Col s={12} m={12} l={12} xl={12} className='margin-top-100'>
                                <TextInput
                                    id='username'
                                    value={props.username}
                                    label='Nombre de usuario'
                                    icon={<Icon className='blue' large>person</Icon>}
                                    placeholder='Ingresa el nombre de usuario' 
                                    noLayout
                                    onChange={(event) => props.setUsername(event.target.value)}
                                    onFocus={() => props.setActiveInput(`login-usuario`, 'spanish', 'login-usuario', props.username)} 
                                />
                            </Col>
                            <Col s={12} m={12} l={12} xl={12} className='margin-button-100'>
                                <TextInput 
                                    id='password'
                                    value={props.password}
                                    label='Contraseña'
                                    password
                                    icon={<Icon className='blue' large>lock</Icon>}
                                    placeholder='Ingresa la contraseña'
                                    noLayout
                                    onChange={(event) => props.setPassword(event.target.value)}
                                    onFocus={() => props.setActiveInput(`login-password`, 'spanish', 'login-password', props.password)} 
                                />
                            </Col>
                        </div>
                        <div className='login-actions'>
                            {(props.message)? <div className='red-message'>Usuario o Contraseña incorrectos</div> : <div></div>}
                            {
                                (props.button)?
                                    <div>
                                        <Button type="submit" waves="light" large>Iniciar Sesión <Icon right>send</Icon></Button>
                                    </div> 
                                    :
                                    <Preloader size='small'/>
                            }
                        </div>
                    </div>
                </form>
            </Col>
            <Col s={0} m={2} l={3} xl={4}>
            </Col>
        </Row>    
    )
}

export { LoginView as default }
