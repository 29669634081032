import React from 'react'
import ReactDOM from 'react-dom'
import IndexContainer from './components/containers/index.container'
import 'materialize-css/dist/css/materialize.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '@progress/kendo-theme-default/dist/all.css';

ReactDOM.render(<IndexContainer />, document.getElementById('root'))

// if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
//     if (document.body.requestFullScreen) {
//         document.body.requestFullScreen();
//     } else if (document.body.mozRequestFullScreen) {
//         document.body.mozRequestFullScreen();
//     } else if (document.body.webkitRequestFullScreen) {
//         document.body.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
//     } else if (document.body.msRequestFullscreen) {
//         document.body.msRequestFullscreen();
//     }
// } else {
//     if (document.cancelFullScreen) {
//         document.cancelFullScreen();
//     } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//     } else if (document.webkitCancelFullScreen) {
//         document.webkitCancelFullScreen();
//     } else if (document.msExitFullscreen) {
//         document.msExitFullscreen();
//     }
// }
