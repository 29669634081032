import { formatResponse, get, post, put, getApiEndpoint, getWebsiteEndpoint, transformApiUrl, getResultResponse, printError } from '../helpers/index.js';
import axios from 'axios';

class CustomerService {

  constructor(client) {
    /*this.client = axios.create({
      baseURL: getWebsiteEndpoint(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    });
    this.apiEndpoint = getWebsiteEndpoint()+'ajax';*/
  }

  async getGeography()
  {
    console.log("getGeography");
    const getResponse = await fetch("/api/geography")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getNeighborhoods( cityId )
  {
    const getResponse = await fetch("/api/cities?" + new URLSearchParams({
        cityId: cityId
      }).toString())
    .then( (data) => data.json() )
    .catch( (error) => error )
    return this.fetchResponse(getResponse);
  }

  async sendMail(email, salesOrderId)
  {
    const getResponse = await fetch("/api/sendmail?" + new URLSearchParams({
        email: email,
        id: salesOrderId
      }).toString()
    )
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  fetchResponse(response)
  {
    try
    {
      const result = response["result"][0]["data"];
      if ( response["result"][0]["status"] === "error" )
      {
        printError(JSON.stringify(response), "Api Error: website fetch");
      }
      return result;
    }
    catch ( error )
    {
      printError(JSON.stringify(error), "Api Error: website")
      return [];
    }
  }

}

export { CustomerService as default };
