import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class PriceListService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'priceList';
    this.priceListKey = {
      MAYOREO: 'MAYOREO',
      MENUDEO: 'MENUDEO',
      YELOS: 'YELOS',
    };
  }

  async all() {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { productId: '' },
    });
    return formatResponse(getResponse);
  }

  async findByProductId(productId) {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { productId },
    });
    return formatResponse(getResponse);
  }
}

export { PriceListService as default };
