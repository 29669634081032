import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class PatternService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'model';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id: '',
        categoryId: '',
        brandId: '',
        genderId: '',
        styleId: '',
      },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id,
        categoryId: '',
        brandId: '',
        genderId: '',
        styleId: '',
      },
    });
    const [pattern] = formatResponse(getResponse);
    return pattern;
  }

  /**
   * Returns patterns related by passed brand, category, gender and style identifier.
   * @param {Object} params
   * @param {string} params.brandId
   * @param {string} params.categoryId
   * @param {string} params.genderId
   * @param {string} params.styleId
   * @return {Object[]}
   */
  async relatedBy(params) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const {
      categoryId = '',
      brandId = '',
      genderId = '',
      styleId = '',
    } = params;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id: '',
        categoryId,
        brandId,
        genderId,
        styleId,
      },
    });
    return formatResponse(getResponse);
  }
}

export { PatternService as default };
