import axios from 'axios';
import config from '../config.js';

class ClienteService {
  getClientes = async (cliente) => {
    let response = await axios.get(`${config.apiUrl}clientes/${cliente}`);

    return response.data.data.clientes;
  };
  updateCorreoCliente = async (cliente, nuevoCorreo) => {
    let result;
    try {
      result = await axios.post(
        `${config.apiUrl}update/correo/cliente?nom_cte=${encodeURIComponent(
          cliente
        )}&email=${nuevoCorreo}`
      );
    } catch (exception) {
      result = [];
    } finally {
      return result;
    }
  };

  enviarCorreoCliente = async (
    correo,
    numeroPedido,
    remision,
    cliente,
    vendedor,
    usuario,
    comentarios,
    direccion,
    fecha,
    hora,
    subtotal,
    descuento,
    iva,
    total,
    articulos
  ) => {
    // JOSE GARCIA GUERRA || pepe@miplayera.com.mx
    let result = null;
    try {
      result = await axios.post(
        `${config.apiUrl}enviarCorreoCliente?correo=${correo}&pedido=${numeroPedido}&remision=${remision}&cliente=${cliente}&vendedor=${vendedor}&usuario=${usuario}&comentarios=${comentarios}&direccion=${direccion}&fecha=${fecha}&hora=${hora}&subtotal=${subtotal}&descuento=${descuento}&iva=${iva}&total=${total}`,
        { data: { articulos } }
      );
    } catch (exception) {
      result = undefined;
    } finally {
      return result;
    }
  };

  checkSupervisor = async (usuario, password) => {
    let result = null;
    try {
      result = await axios.get(
        `${config.apiUrl}validar/supervisor?usuario=${usuario}&password=${password}`
      );
      if (result.data.data.status === 200) {
        result = result.data.data.result;
      } else {
        result = [];
      }
    } catch (exception) {
      result = [];
    } finally {
      return result;
    }
  };
}

export { ClienteService as default };
