import React from 'react';

function Loader() {
    return (
        <React.Fragment>
            <div className="progress">
                <div className="indeterminate"></div>
            </div>
        </React.Fragment>
    )
}
export {Loader as default}