import React from 'react';
// import { Row, Col, Card} from 'react-materialize';
import './pedido-print.css';

const PedidoPrintView = (props) => {
  let descuento = 0;
  let iva = ((props.total - descuento) * 0.16).toFixed(2);
  let total = (Number(props.total) + Number(iva) - Number(descuento)).toFixed(
    2
  );

  return (
    <div id="printContainer">
      <div style={{ widht: '100% !important' }}>
        <div style={{ widht: '100% !important' }}>
          <section>
            <div>
              <h2>GRUPO MI PLAYERA DE MEXICO SA DE CV</h2>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>Pedido: {props.numeroPedido}</p>
                      <p>Remision: {props.numeroRemision}</p>
                      <p>Vendido a: {props.clienteSeleccionado.name}</p>
                      <p>
                        Vendedor:{' '}
                        {props.newAgente
                          ? props.newAgente.nom_age
                          : props.user.name}
                      </p>
                      <p>
                        Usuario:{' '}
                        {props.newAgente
                          ? props.newAgente.cve_age
                          : props.user.username}
                      </p>
                      <p>Comentarios: {props.comentarioPedido}</p>
                      <p>
                        Dirección:{' '}
                        {props.clienteSeleccionado.billingAddress
                          ? `${props.clienteSeleccionado.billingAddress}, ${props.clienteSeleccionado.billingNeighborhood}, ${props.clienteSeleccionado.billingCity}, ${props.clienteSeleccionado.billingState}`
                          : ''}
                      </p>
                    </td>
                    <td>
                      <p>Fecha: {props.formatDate}</p>
                      <p>Hora: {props.formatTime}</p>
                      <p>Sub Total: $ {props.total}</p>
                      <p>Descuento: ${descuento}</p>
                      <p>IVA: $ {iva}</p>
                      <p>Total: $ {total}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <table
                  style={{
                    display: 'flex !important',
                    flexDirection: 'row',
                    justifyContent: 'center !important',
                    width: '100% !important',
                  }}
                >
                  <thead>
                    <tr>
                      <th>Codigo</th>
                      <th>Articulo</th>
                      <th>Talla</th>
                      <th>Color</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.productosLocalStorage.map((producto, index) => {
                      return (
                        <tr key={index} style={{ fontSize: 12 }}>
                          <td>{producto.codigo}</td>
                          <td>{producto.descripcion}</td>
                          <td>{producto.talla}</td>
                          <td>{producto.color?.toUpperCase()}</td>
                          <td>{producto.cantidadview}</td>
                          <td>$ {parseFloat(producto.prec_set).toFixed(2)}</td>
                          <td>
                            $ {parseFloat(producto.prec_set * producto.cantidad).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export { PedidoPrintView as default };
