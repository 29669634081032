import React from 'react';
import './pedido-reprint.css';

const PedidoReprintView = (props) => {
  let prop = props.onProps;

  return (
    <div id="reprintContainer">
      <div>
        <div>
          <section>
            <div>
              <h2>GRUPO MI PLAYERA DE MEXICO SA DE CV</h2>
              <table>
                <tbody>
                  <tr>
                    <td>
                      Folio: {prop.numeroPedido}
                      <br></br>
                      Remision: {prop.remision}
                      <br></br>
                      Vendido a: {prop.cliente}
                      <br></br>
                      Vendedor: {prop.vendedor}
                      <br></br>
                      Usuario: {prop.usuario}
                      <br></br>
                      Comentarios: {prop.comentarios}
                      <br></br>
                      Dirección: {prop.direccion}
                      <br></br>
                    </td>
                    <td>
                      Fecha: {prop.fecha}
                      <br></br>
                      Status: {prop.status}
                      <br></br>
                      Sub Total: $ {prop.subtotal}
                      <br></br>
                      Descuento: ${prop.descuento}
                      <br></br>
                      IVA: $ {prop.iva}
                      <br></br>
                      Total: $ {prop.total}
                      <br/>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Codigo</th>
                      <th>Articulo</th>
                      <th>Talla</th>
                      <th>Color</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>IVA</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prop.articulos.map((producto, index) => {
                      return (
                        <tr key={index} style={{ fontSize: 12 }}>
                          <td>{producto.productCode}</td>
                          <td>{producto.product}</td>
                          <td>{producto.size}</td>
                          <td>{producto.color}</td>
                          <td>{producto.qtyOrd}</td>
                          <td>{producto.price}</td>
                          <td>{producto.IVA}</td>
                          <td>{producto.subTotal}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export { PedidoReprintView as default };
