import { Button, Col, Container, Row, TextInput } from 'react-materialize';

import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import ModalRemisionItem from './modal-remision.view';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';
import PedidoReprintView from './../modal-reporte/pedido-reprint';
import printJS from 'print-js';

// servicios

class ModalRemision extends React.Component {
  state = {
    loading: false,
    numPedido: '',
    itemsPedido: null,
    itemsPedidoDet: null,
    btnBuscarEstado: false,
    propsPrint:{
      articulos: []
    },
    lastSearch: ''
  };

  async componentDidMount() {
    this.setState({
      numPedido: '',
    });
  }

  handleChange = (e) => {
    this.setState({
      numPedido: e.target.value,
    });
  };

  formatResultPedido = (resultPedidos) => {
    return resultPedidos.map((remisión) => {
      return {
        ...remisión,
        Pedido: '315062',
        Vendedor: '84',
        Cliente: '40380',
        'Fecha de remisión': '2022-09-10',
        'Estatus de remisión': 'ACTIVO',
        Total: '1798.46',
        'Comentarios de remisión':
          'PV - domicilio fiscal Dirección, Guadalupe, col. R',
        'Descuento general': '0.000000',
      };
    });
  };

  handleSearchPedido = async () => {
    let numPedido = this.state.numPedido.trim();
    if (this.state.lastSearch === numPedido) 
    {
      M.toast({
        html: 'Es la misma remisión',
        classes: 'message-success',
      });
      return;
    }
    this.setState({
      loading: true,
      lastSearch: numPedido
    });
    try {
      let result = await qad.catalog.salesOrder.findRemisionById(numPedido);
      console.log(result);
      if (!result) {
        M.toast({ html: 'No se encontro numero de remisión' });
        this.setState({
          itemsPedido: null,
        });
      } else {
        this.setState({
          itemsPedido: [result]
          //itemsPedidoDet: await this.handleGetDetallePedido(numPedido), // antes se obtenia el detalle para modificar la exitencia this.getItemsDetalle(result.detalle, result.cabecera[0].lugar) // propiedad usada para almacenar articulos para retornar inventario
        });
      }
    } catch (error) {
      this.setState({
        itemsPedido: null,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      numPedido: null,
      itemsPedido: null,
      itemsPedidoDet: null,
      lastSearch: ''
    });
    this.props.onClose();
  };
  // muestra remisión
  showPedido = () => {
    if (this.state.itemsPedido) {
      return (
        <ModalRemisionItem
          items={this.state.itemsPedido}
          onCancel={this.handleCancelarPedido}
          onGenerar={this.handleGenerarPedido}
          print={this.print}
        />
      );
    } else {
      return null;
    }
  };

  print = async (salesOrderId) => {
    const order = this.state.itemsPedido[0];
    this.setState(
      {
        reportePrint: true,
        propsPrint: {
          numeroPedido: order.salesOrderId,
          remision: order.remisionId,
          cliente: order.customer,
          vendedor: order.salesPerson,
          comentarios: order.remarks,
          direccion: order.address,
          fecha: order.orderDate,
          status: order.status,
          subtotal: order.subTotal,
          descuento: order.discount ? order.discount : 0 ,
          iva: order.IVA,
          total: order.total,
          articulos: order.detail,
          usuario: JSON.parse(localStorage.getItem('user')).name
        },
      },
      async function () {
        printJS({
          printable: 'reprintContainer',
          type: 'html',
          targetStyles: ['*'],
        });

        this.setState({
          reportePrint: false,
        });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalRemisionIsOpen}
          onClose={this.handleCloseModal}
        >
          <Container>
            <Row>
              <Col s={12} m={12} l={12} xl={12}>
                <h3 className="m3">Remisión</h3>
                <TextInput
                  id="numPedidoInput"
                  s={12}
                  m={12}
                  l={12}
                  xl={12}
                  value={this.state.numPedido || ''}
                  onChange={this.handleChange}
                  label="Numero de remisión"
                />
                <Button
                  className="m3"
                  node="button"
                  waves="light"
                  disabled={this.state.btnBuscarEstado}
                  onClick={this.handleSearchPedido}
                >
                  Buscar remisión
                </Button>
              </Col>
            </Row>
            <br></br>
            <br></br>
            {this.state.loading ? <LoadingView /> : this.showPedido()}
            <div style={{display:"none"}} >
              <PedidoReprintView onProps={this.state.propsPrint}/>
            </div>
          </Container>
        </Modal>
      </React.Fragment>
    );
  }

  handleCancelarPedido = async () => {
    let result;
    try {
      this.setState({
        btnBuscarEstado: true,
      });

      // modal loading
      Swal.fire({
        title: 'Cancelando',
        html: 'Procesando cancelaci&oacuten..',
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if ( !this.state.numPedido ) {
        throw Error();
      }
      result = await  await qad.catalog.salesOrder.cancelRemision(this.state.numPedido);
    } catch (error) {
      M.toast({
        html: 'Ocurrio un error al cancelar',
        classes: 'message-error',
      });
    } finally {
      this.setState({
        btnBuscarEstado: false,
        numPedido: null
      });
      Swal.close();
      return result;
    }
  };

  handleGetDetallePedido = async (numPedido) => {
    let detallePedido;
    try {
      detallePedido = await qad.catalog.salesOrder(numPedido);
    } catch (e) {
      console.log(`An error has ocurred: ${e}`);
    } finally {
      return detallePedido;
    }
  };

  handleGenerarPedido = async (salesOrderId) => {
    const order = await qad.catalog.salesOrder.findById(
      salesOrderId
    );
    if (order.detail.length > 0) {
      order.detail.forEach( async (item, i) =>
        {
          
          const {existencias, priceList} = await this.props.getInventoryAndPrices(item.productId);
          const articulo = {
            cantidad: item.qtyOrd,
            color: item.color,
            inventario: existencias.reduce((acc, b) => acc + parseInt(b.stock), 0 ),
            existencias,
            priceList,
            productId: item.productId,
            cve_prod: item.productId,
            prec_set: item.price,
            talla: item.size,
            colorId: item.colorId,
            codigo: item.productCode,
            modelo: item.productModel,
            descripcion: item.product
          };
          this.props.insertIntoLocalStorage(articulo);
        })
    }
  };
}
export { ModalRemision as default };
