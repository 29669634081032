import { Button, Col, Container, Row, TextInput } from 'react-materialize';

import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import ModalInvoiceItem from './modal-invoice.view';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';
import InvoiceReprintView from './../modal-reporte/pedido-reprint';
import printJS from 'print-js';
import AsyncSelect from 'react-select/async';

// servicios

class ModalOC extends React.Component {
  state = {
    loading: false,
    numInvoice: '',
    itemsInvoice: null,
    btnBuscarEstado: false,
    lastSearch: '',
    brand: null,
    patterns: [],
    loadingPatterns: false,
    pattern: null,
    products: {
      colorKeys : [],
      colorItems : []
    },
    header: {},
    date: null
  };

  async componentDidMount() {
    this.setState({
      numInvoice: '',
    });
  }

  handleChange = (e) => {
    this.setState({
      numInvoice: e.target.value,
    });
  };

  handleSearchInvoice = async () => {
    let numInvoice = this.state.numInvoice.trim();
    if (this.state.lastSearch === numInvoice) 
    {
      M.toast({
        html: 'Es la misma Factura',
        classes: 'message-success',
      });
      return;
    }
    this.setState({
      loading: true,
      lastSearch: numInvoice
    });
    try {
      let result = await qad.catalog.salesOrder.findInvoiceById(numInvoice);
      console.log(result);
      this.setState({
        itemsInvoice: [result]
      });
      if (!result) {
        M.toast({ html: 'No se encontro numero de Factura' });
        this.setState({
          itemsInvoice: null,
        });
      }
    } catch (error) {
      this.setState({
        itemsInvoice: null,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      numInvoice: null,
      itemsInvoice: null,
      itemsInvoiceDet: null,
      lastSearch: ''
    });
    this.props.onClose();
  };
  // muestra Factura
  showInvoice = () => {
    if (this.state.itemsInvoice && this.state.itemsInvoice.length > 0) {
      return null;
    } else {
      return null;
    }
  };

  setBrand = async (brand) => {
    let today = new Date();
    let strDate = today.getFullYear()*"-"+today.getMonth()+"-"+today.getDate();  
    this.setState({
      brand: brand,
      strDate: strDate,
      loadingPatterns: true
    });
    if (brand)
    {
      let data = JSON.parse(localStorage.getItem( "OC_model_"+brand.value+"_"+strDate ));
      if ( !data )
      {
        data = {};
      }
      localStorage.setItem( "OC_model_"+brand.value+"_"+strDate, JSON.stringify(data));
      let resultPatterns = await qad.catalog.pattern.relatedBy({brandId: brand.value});
      try
      {
        if (resultPatterns)
        {
          this.setState({
            patterns: resultPatterns,
            loadingPatterns: false
          });
          this.getProducts(resultPatterns[0]);
        }
      }
      catch( error )
      {
        console.error(error);
      }
    }
  }

  getProducts = async (pattern) => {
    this.setState({
      pattern: pattern,
      loading: true
    });
    if (pattern)
    {
      const currentData = JSON.parse(localStorage.getItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate ));
      const resultProducts = await qad.catalog.product.relatedBy({brandId: this.state.brand.value, modelId: pattern.id});
      try
      {
        if (resultProducts)
        {
          let header = {};
          let productsByColor = {
            colorKeys : [],
            colorItems : []
          };
          resultProducts.map( (product) => {
            if ( currentData[product.id] )
            {
              product.qty = currentData[product.id].qty;
            }
            else
            {
              product.qty = 0;
            }
            if ( productsByColor.colorKeys.indexOf(product.colorId) === -1 )
            {
              let backLinear = 'linear-gradient(90deg';
              let colors = product.colorPantoneHexa.split(',');
              let backPercent = ( 100 / colors.length );
              colors.map( (color) => {
                backLinear += ", "+color+' '+backPercent +'%' 
              });
              if ( colors.length == 1 )
              {
                backLinear += ", #fff";
              }
              backLinear += ")";
              productsByColor.colorKeys.push(product.colorId);
              productsByColor.colorItems.push({
                colorName: product.colorLabel,
                colorId: product.colorId,
                colorRGB: backLinear,
                genderKeys: [product.genderId],
                genderItems: [{
                  genderName: product.genderName,
                  genderId: product.genderId,
                  sizeKeys: [product.sizeId],
                  sizeItems: [{
                    sizeName: product.sizeName,
                    sizeId: product.sizeId,
                    sizeOrder: parseInt(product.sizeOrder),
                    products: [product]
                  }]
                }]
              });
            }
            else
            {
              const colorPosition = productsByColor.colorKeys.indexOf(product.colorId);
              let genderPosition = 0;
              let sizePosition = 0;
              if( productsByColor.colorItems[colorPosition].genderKeys.indexOf(product.genderId) === -1 )
              {
                productsByColor.colorItems[colorPosition].genderKeys.push(product.genderId);
                productsByColor.colorItems[colorPosition].genderItems.push({
                  genderName: product.genderName,
                  genderId: product.genderId,
                  sizeKeys: [product.sizeId],
                  sizeItems: [{
                    sizeName: product.sizeName,
                    sizeId: product.sizeId,
                    sizeOrder: parseInt(product.sizeOrder),
                    products: [product]
                  }]
                });
                genderPosition = productsByColor.colorItems[colorPosition].genderKeys.length -1;
              }
              else
              {
                genderPosition = productsByColor.colorItems[colorPosition].genderKeys.indexOf(product.genderId);
                if ( productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.indexOf(product.sizeId) === -1 )
                {
                  productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.push(product.sizeId);
                  productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeItems.push({
                      sizeName: product.sizeName,
                      sizeId: product.sizeId,
                      sizeOrder: parseInt(product.sizeOrder),
                      products: [product]
                  });
                  sizePosition = productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.length - 1 ;
                }
                else
                {
                  sizePosition = productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.indexOf(product.sizeId);
                  productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeItems[sizePosition].products.push(product);
                }
              }
              
            }
            if( !header[product.genderId] )
            {
              header[product.genderId] = {
                name: product.genderName,
                id:  product.genderId,
                sizesName : [product.sizeName],
                sizes: [{ name:product.sizeName, id:product.sizeId, order: parseInt(product.sizeOrder) }]
              }
            }
            else
            {
              if ( header[product.genderId].sizesName.indexOf(product.sizeName) === -1 )
              {
                header[product.genderId].sizesName.push(product.sizeName);
                header[product.genderId].sizes.push({ name:product.sizeName, id:product.sizeId, order: parseInt(product.sizeOrder) });
              }
            }
          });
          this.setState({
            header: header,
            products: productsByColor,
            loading: false
          });
        }
      }
      catch( error )
      {
        console.error(error);
      }
    }
  }

  modifyProduct = async (edit, value) => {
    let products = this.state.products;
    let currentVal = value;
    if ( !value )
    {
      currentVal = 0;
    }
    let product = products.colorItems[edit.cIndex].genderItems[edit.gIndex].sizeItems[edit.sIndex].products[edit.pIndex];
    product.qty = currentVal;
    let items = JSON.parse(localStorage.getItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate));
    if ( product.qty > 0 )
    {
      items[product.id] = product;
    }
    else
    {
      delete items[product.id];
    }
    localStorage.setItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate, JSON.stringify(items))
    this.setState({
      products: products
    });
  }


  render() {
    this.brands = this.props.catalog.brands.map((b) => { return { label: b.name, value:b.id } });
    let pathByGender = {} ;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.handleCloseModal}
        >
          <Container className="OCContainer">
            <Row>
              <Col s={12} m={12} l={12} xl={12}>
                <h4 className="m3">Marca</h4>
                <AsyncSelect
                  id="brands"
                  placeholder="Proveedor"
                  cacheOptions
                  isClearable
                  value={this.state.brand}
                  onChange={(value) => this.setBrand(value) }
                  defaultOptions={this.brands} />
              </Col>
            </Row>
            <br/>
            { 
              this.state.loadingPatterns 
              ? 
                <LoadingView /> 
              : 
                <Row>
                  <Col s={12}>
                  {
                    this.state.patterns.map( (pattern) => {
                      return (
                        <Button onClick={()=> this.getProducts(pattern) } key={pattern.id}>{pattern.name}</Button>
                      )
                    })
                  }
                  </Col>
                </Row>
            }
            { 
              this.state.loading 
              ? 
                <LoadingView /> 
              : 
                <Row>
                  <Col s={12}>
                  <table className="table bordered table-bordered">
                    <thead style={{position: "sticky",top: 0, background: "#fff"}}>
                      <tr>
                        <td rowSpan={2}>Color</td>
                        {
                          Object.values(this.state.header).map((headG)=>{
                            return (
                              <td key={headG.id} colSpan={headG.sizes.length}>{headG.name}</td>
                            )
                          })
                        }
                      </tr>
                      <tr>
                        {

                          Object.values(this.state.header).map((headG)=>{
                            pathByGender[headG.id] = [];
                            return headG.sizes.sort( (a, b) => {
                                if (a.order > b.order) {
                                  return 1;
                                }
                                if (a.order < b.order) {
                                  return -1;
                                }
                                return 0;
                              }).map( (size) => {
                                pathByGender[headG.id].push(size.id);
                                return (<td key={"cel_"+size.name}>{size.name}</td>)
                              } )
                          })
                        }
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.products.colorItems.map( (color, cIndex) => {
                        let idex = 0;
                        return (
                          <tr key={color.colorId}>
                            <td key={"cell_"+color.colorId} className="colorRow">
                              <div className="colorContainer">
                                <div key={"name_"+color.colorId} className="colorName">{color.colorName}</div>
                                <div key={"rgb_"+color.colorId} className="colorRGB" style={{background: color.colorRGB}}>&nbsp;</div>
                              </div>
                            </td>
                            {
                              color.genderItems.map( (genderItem, gIndex) => {
                                let currentSizeIndex = 0;
                                return genderItem.sizeItems.sort( (a, b) => {
                                  if (a.sizeOrder > b.sizeOrder) {
                                    return 1;
                                  }
                                  if (a.sizeOrder < b.sizeOrder) {
                                    return -1;
                                  }
                                  return 0;
                                }).map((sizeItem, sIndex)=>{
                                  let emptyElements = [];
                                  let currentSize = null;
                                  do
                                  {
                                    idex += 1;
                                    if ( pathByGender[genderItem.genderId][currentSizeIndex] === sizeItem.sizeId )
                                    {
                                      currentSize = pathByGender[genderItem.genderId][currentSizeIndex];
                                      currentSizeIndex += 1;
                                    }
                                    else
                                    {
                                      emptyElements.push((<td key={idex+"_"+sizeItem.sizeName} className="product" id={idex+"_"+sizeItem.sizeName}>&nbsp;</td>));
                                      currentSizeIndex += 1;
                                    }
                                  } while ( currentSize === null && currentSizeIndex <= genderItem.sizeItems.length );
                                  return [ 
                                    ...emptyElements,
                                    ...sizeItem.products.map( (product, pIndex) => {
                                      return (
                                          <td key={product.id} className="product" id={product.id} title={product.sizeName}>
                                            <input 
                                              key={"input_"+product.id}
                                              title={product.sizeLabel} 
                                              value={product.qty} 
                                              type="number" 
                                              onChange={(e) => this.modifyProduct({cIndex, gIndex, sIndex, pIndex}, e.target.value) } />
                                          </td>
                                        )
                                      }
                                    )
                                  ];
                                })
                              })
                            }
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                  <Button disabled={!this.state.brand} onClick={()=> this.generateOC() }>Generar OC</Button>
                </Col>
              </Row>
            }
          </Container>
        </Modal>
      </React.Fragment>
    );
  }

  generateOC = async () => {
    if ( this.state.brand )
    {
      let data = JSON.parse(localStorage.getItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate ));
      console.log(data);
    }
    else
    {
      Swal.fire({title:"No se ha seleccionado la Marca"});
    }
  };
}
export { ModalOC as default };
