import './pedido.css';

import {
  Autocomplete,
  Button,
  Card,
  Col,
  Icon,
  Row,
  TextInput,
  Textarea,
  Select
} from 'react-materialize';

import AsyncSelect from 'react-select/async';

import AgentesService from '../../../services/agente.service';
import ClienteService from '../../../services/cliente.service';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';

// import M from 'materialize-css';

// services

function checkTargetElement(_array, idx) {
  if (_array.includes(String(idx))) {
    return true;
  } else {
    return false;
  }
}
function checkControlTargetElement(_array, idx) {
  if (_array.includes(String(idx))) {
    return true;
  } else {
    return false;
  }
}

class PedidoView extends React.Component {
  state = {
    targetElementOffer: '',
    targetControlOffer: '',
    targetElementPrice: '',
    targetControlPrice: ''
  };

  user = JSON.parse(localStorage.getItem('user'));
  customers = [];

  componentDidMount = async () => {
    /*const agentes = await qad.catalog.salesPerson.all();
    this.setState({
      agentes,
    });*/
  };

  filterOptions = (inputValue) => {
    //console.log(inputValue, inputValue.lenght, inputValue.length);
    if ( inputValue.length > 3 )
    {
      this.filtredCustomers = this.customers.filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    return this.filtredCustomers;
  }

  promiseOptions = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterOptions(inputValue));
    });
  }


  render() {
    let total = 0;
    let iva = 0;
    let subtotal = 0;
    this.customers = this.props.catalog.customers.map( (el)=> { return {"value": el.id, "label": el.id +" - "+ el.name+ " - " + el.billingRfc } } );
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h6>
              Vendedor:{' '}
              <a
                href="#/"
                onClick={async () => {
                  if(!this.state.agentes)
                  {
                    return;
                  }
                  let options = {};
                  for (let i = 0; i < this.state.agentes.length; i++) {
                    options[String(this.state.agentes[i].id)] = String(
                      this.state.agentes[i].name
                    );
                  }
                  await Swal.fire({
                    title: 'Cambio de vendedor',
                    text: 'selecciona un vendedor',
                    input: 'select',
                    inputOptions: options,
                    reverseButtons: true,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Aceptar',
                  }).then((response) => {
                    if (response.isConfirmed) {
                      let selectedVendedor = this.state.agentes.filter(
                        (value) => {
                          return response.value === value.id;
                        }
                      );
                      this.props.setNuevoAgente(selectedVendedor[0]);
                    }
                  });
                }}
              >
                {this.props.newAgente
                  ? this.props.newAgente.name
                  : this.user.name}
              </a>
            </h6>
          </Col>
          <Col xl={12} l={12} m={12} s={12}>
            <Card>
              <Row>
                {this.props.productosLocalStorage.length > 0 ? (
                  <div>
                    <Col
                      xl={12}
                      l={12}
                      m={12}
                      s={12}
                      className="center button-top-pedido"
                    >
                      <Col xl={6} l={6} m={6} s={6}>
                        <h6 className="envio-pregunta">
                          Selecciona el cliente
                        </h6>
                        <AsyncSelect 
                          placeholder="Clientes" 
                          cacheOptions 
                          defaultOptions 
                          isClearable 
                          value={this.props.cliente}
                          onChange={(value) => {this.props.seleccionarCliente(value);}} 
                          defaultOptions={this.customers.slice(0,100)} 
                          loadOptions={this.promiseOptions}/>
                      </Col>
                      <Col xl={6} l={6} m={6} s={6}>
                        <h6 className="envio-pregunta">
                          ¿Necesitas envio para tu compra? (Opcional)
                        </h6>

                        <select
                          value={this.props.envioSeleccionado}
                          className="combo-pedido"
                          onChange={this.props.onSelectEnvio}
                        >
                          <option value="0">Selecciona tu envio</option>
                          {this.props.envios.map((envio, key) => (
                            <option value={envio.id} key={key + 1}>
                              {envio.desc_prod +
                                (envio.desc_med ? ' ' + envio.desc_med : '')}
                            </option>
                          ))}
                        </select>
                        <Button
                          medium="true"
                          className="button-pedido"
                          onClick={(event) => {
                            this.props.agregarEnvio(
                              this.props.envioSeleccionado
                            );
                          }}
                        >
                          Agregar envio
                        </Button>
                      </Col>
                    </Col>
                    <Col
                      xl={12}
                      l={12}
                      m={12}
                      s={12}
                      className="center button-top-pedido"
                    >
                      <Col xl={6} l={6} m={6} s={6}>
                        <Textarea
                          label="¿Deseas agregar comentarios al pedido? (Opcional)"
                          xl={12}
                          l={12}
                          m={12}
                          s={12}
                          value={this.props.comentarioPedido}
                          onChange={this.props.onChangeComentarioPedido}
                          onFocus={() =>
                            this.props.setActiveInput(
                              `comentarios`,
                              'spanish',
                              'comentarios',
                              this.props.comentarioPedido
                            )
                          }
                        />
                      </Col>
                      <Col xl={6} l={6} m={6} s={6} className="hidden">
                        <TextInput
                          label="¿El pedido necesita descuento? (Opcional)"
                          type="number"
                          xl={12}
                          l={12}
                          m={12}
                          s={12}
                          min={1}
                          value={this.props.descuentoPedido}
                          onChange={this.props.onChangeDescuento}
                          onFocus={() =>
                            this.props.setActiveInput(
                              `descuento`,
                              'number',
                              'descuento',
                              this.props.descuentoPedido
                            )
                          }
                        />
                      </Col>
                    </Col>

                    <Col
                      xl={12}
                      l={12}
                      m={12}
                      s={12}
                      className="center button-top-pedido"
                    >

                      <Button
                        large
                        onClick={(event) => {
                          this.props.colocarPedido(
                            this.props.productosLocalStorage,
                            false
                          );
                        }}
                      >
                        Colocar Pedido sin Remision
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button large onClick={this.props.regresarArticulos}>
                        Regresar a los articulos
                      </Button>{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        large
                        onClick={(event) => {
                          this.props.colocarPedido(
                            this.props.productosLocalStorage
                          );
                        }}
                      >
                        Colocar Pedido
                      </Button>
                    </Col>
                    <Col
                      xl={12}
                      l={12}
                      m={12}
                      s={12}
                      className="center-align button-top-pedido"
                    >
                      <h5 className="titulo-pedido">Articulos del pedido</h5>
                    </Col>
                    <Col xl={12} l={12} m={12} s={12}>
                      <table className="striped center highlight tablePedido">
                        <thead>
                          <tr>
                            {/* 21% de espacio disponible */}
                            <th style={{ width: '30%' }}>Articulo</th>
                            <th style={{ width: '10%', textAlign: 'center' }}>
                              Talla
                            </th>
                            <th style={{ width: '10%' }}>Color</th>
                            <th style={{ width: '10%' }}>Cantidad</th>
                            <th style={{ width: '25%' }}>Precio</th>
                            {/* <th style={{ width: '18%' }}>Descuento</th> */}
                            <th style={{ width: '15%' }}>Acciones</th>
                          </tr>
                        </thead>
                        <tbody className="z-depth-2">
                          {this.props.productosLocalStorage.map(
                            (producto, index) => {
                              console.log("localProd", producto);
                              subtotal += parseFloat( producto.prec_prod ) * parseInt( producto.cantidad )
                              iva += parseFloat(subtotal) * 0.16;
                              return (
                                <tr key={index}>
                                  <td>{producto.descripcion}</td>
                                  <td>{producto.talla}</td>
                                  <td>
                                    {(producto.color || '').toUpperCase()}
                                  </td>
                                  <td>
                                    <TextInput
                                      className="input-pedido"
                                      placeholder="Ingresa la cantidad"
                                      type="number"
                                      min={1}
                                      value={`${producto.cantidad}`}
                                      onChange={(event) => {
                                        this.props.cambiarCantidad(
                                          event.target.value,
                                          producto
                                        );
                                      }}
                                      onFocus={() =>
                                        this.props.setActiveInput(
                                          `cantidad-${index}`,
                                          'number',
                                          'pedido',
                                          producto
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <select
                                      style={{ width: '100px' }}
                                      className="combo-pedido"
                                      value={ ( !producto.priceListIdSet  ? ( this.props.clienteCompleto ? this.props.clienteCompleto.pricesList : this.props.catalog.priceListType.MENUDEO) : producto.priceListIdSet )  }
                                      onChange={(e) => {
                                        const priceList =
                                            producto.priceList.find(
                                              ({ id }) =>
                                                id === e.target.value
                                              );
                                              if ( priceList )
                                              {
                                                producto.prec_set = parseFloat(priceList.price);
                                                producto.prec_prod = parseFloat(priceList.price).toFixed(2);
                                                producto.priceListIdSet = priceList.id;
                                                this.props.setPriceProductosLocalStorage(
                                                  producto,
                                                  index
                                                );
                                              }
                                              else
                                              {
                                                Swal.fire({
                                                  title: 'Sin lista de precios',
                                                  text: 'No hay lista de precios para el producto',
                                                  confirmButtonText: 'Aceptar',
                                                })
                                              }
                                            }}
                                          >
                                          {producto.priceList.map(
                                            (priceList, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={priceList.id}
                                                >
                                                  $ {priceList.price} (
                                                  {priceList.id})
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                  </td>
                                  <td>
                                    <Button
                                      className="red lighten-1"
                                      onClick={(event) => {
                                        this.props.eliminarPartida(producto);
                                      }}
                                    >
                                      <Icon className="white-icon-pedido">
                                        remove_circle
                                      </Icon>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </Col>
                    <Col
                      xl={12}
                      l={12}
                      m={12}
                      s={12}
                      className="button-top-pedido"
                    >
                      <Row>
                        <br />
                        <br />
                        <Col xl={12} l={12} m={12} s={12}>
                          <div className="title text-blue">{`SUB TOTAL: $ ${parseFloat(this.props.total).toFixed(2)}`}</div>
                          {/* <div className="title text-blue">{`Descuento: $ ${descuento}`}</div> */}
                          <div className="title text-blue">{`IVA: $ ${parseFloat(this.props.total*0.16).toFixed(2)}`}</div>
                          <div className="title text-blue">{`TOTAL: $ ${parseFloat(this.props.total*1.16).toFixed(2)}`}</div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                ) : (
                  <div>
                    <Col xl={12} l={12} m={12} s={12} className="center-align">
                      <h5 className="titulo-pedido">
                        Selecciona al menos un articulo antes de colocar tu
                        pedido!
                      </h5>
                    </Col>
                    <Col xl={12} l={12} m={12} s={12} className="center">
                      <Button large onClick={this.props.regresarArticulos}>
                        Regresar a los articulos
                      </Button>
                    </Col>
                  </div>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export { PedidoView as default };
