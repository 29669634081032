import './modal-pedidos.css';
import { Button, Col, Row } from 'react-materialize';
import React from 'react';
import Swal from 'sweetalert2';

function ModalInvoiceItem(props) {
  console.log(props);
  return (
    <React.Fragment>
      {props.item.map((item, index) => {
        return (
          <React.Fragment key={item.invoiceId}>
            <div className="containerInvoiceItem">
              <Row>
                <Col s={7}>
                  <b># Factura:</b> {item.invoiceId}
                </Col>
                <Col s={5}>
                    <b>Vendedor:</b> {item.salesPerson}
                </Col>
                <Col s={7}>
                  <b>Cliente:</b> {item.customer}
                </Col>
                <Col s={5}>
                  <b>Estatus:</b> {item.billPaid}
                </Col>
                <Col s={7}>
                  <b>Remision:</b> {item.remisionId}
                </Col>
                <Col s={5}>
                  <b>Pedido:</b> {item.salesOrderId}
                </Col>
                <Col s={7}>
                  <b>Fecha:</b> {(new Date(Date.parse(item.invoiceDate))).toLocaleDateString()}
                </Col>
                <Col s={5}>
                  <b># Cliente:</b> {item.customerId}
                </Col>
              </Row>
              <Row>
                <Col s={12}>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Linea
                        </th>
                        <th>
                          Código
                        </th>
                        <th>
                          Producto
                        </th>
                        <th>
                          Cantidad
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        item.detail.map( (line) => {
                          return (
                            <tr>
                              <td>{line.line}</td>
                              <td>{line.productCode}</td>
                              <td>{line.product}</td>
                              <td>{line.qtyOrd}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
export { ModalInvoiceItem as default };
