import React from 'react';
import './modal-reporte-filtros.css';
import { DatePicker, Row, Col, Button} from 'react-materialize';

function ReporteFiltros(props){

    return (<React.Fragment>
            <h4 className="m3">Ventas x Canal x Agente x Clase</h4>
            <Row xl={12} l={12} m={12} s={12}>
                <Col>
                    <p>Fechas:</p>
                </Col>
                <Col xl={2} l={2} m={2} s={2}>
                    <DatePicker
                        id="fecha1"
                        xl={12} l={12} m={12} s={12}
                        options={{
                            autoClose: true,
                            container: null,
                            defaultDate: null,
                            disableDayFn: null,
                            disableWeekends: false,
                            events: [],
                            firstDay: 0,
                            format: 'yyyy-mm-dd',
                            i18n: {
                            cancel: null,
                            clear: 'Clear',
                            done: null,
                            months: [
                                'Enero',
                                'Febrero',
                                'Marzo',
                                'Abril',
                                'Mayo',
                                'Junio',
                                'Julio',
                                'Agosto',
                                'Septiembre',
                                'Octubre',
                                'Noviembre',
                                'Diciembre'
                            ],
                            monthsShort: [
                                'Ene',
                                'Feb',
                                'Mar',
                                'Abr',
                                'May',
                                'Jun',
                                'Jul',
                                'Ago',
                                'Sep',
                                'Oct',
                                'Nov',
                                'Dic'
                            ],
                            nextMonth: '›',
                            previousMonth: '‹',
                            weekdays: [
                                'Domingo',
                                'Lunes',
                                'Martes',
                                'Miercoles',
                                'Jueves',
                                'Viernes',
                                'Sabado'
                            ],
                            weekdaysAbbrev: [
                                'D',
                                'L',
                                'M',
                                'M',
                                'J',
                                'V',
                                'S'
                            ],
                            weekdaysShort: [
                                'Dom',
                                'Lun',
                                'Mar',
                                'Mier',
                                'Jue',
                                'Vier',
                                'Sab'
                            ]
                            },
                            isRTL: false,
                            maxDate: null,
                            minDate: null,
                            onClose: null,
                            onDraw: null,
                            onOpen: null,
                            onSelect: null,
                            parse: null,
                            setDefaultDate: false,
                            showClearBtn: false,
                            showDaysInNextAndPreviousMonths: false,
                            showMonthAfterYear: false,
                            yearRange: 10
                        }}
                        />
                </Col>
                <Col>
                    <p>a</p> 
                </Col>
                <Col xl={2} l={2} m={2} s={2}>
                    <DatePicker
                        id="fecha2"
                        xl={12} l={12} m={12} s={12}
                        options={{
                            autoClose: true,
                            container: null,
                            defaultDate: null,
                            disableDayFn: null,
                            disableWeekends: false,
                            events: [],
                            firstDay: 0,
                            format: 'yyyy-mm-dd',
                            i18n: {
                            cancel: null,
                            clear: 'Clear',
                            done: null,
                            months: [
                                'Enero',
                                'Febrero',
                                'Marzo',
                                'Abril',
                                'Mayo',
                                'Junio',
                                'Julio',
                                'Agosto',
                                'Septiembre',
                                'Octubre',
                                'Noviembre',
                                'Diciembre'
                            ],
                            monthsShort: [
                                'Ene',
                                'Feb',
                                'Mar',
                                'Abr',
                                'May',
                                'Jun',
                                'Jul',
                                'Ago',
                                'Sep',
                                'Oct',
                                'Nov',
                                'Dic'
                            ],
                            nextMonth: '›',
                            previousMonth: '‹',
                            weekdays: [
                                'Domingo',
                                'Lunes',
                                'Martes',
                                'Miercoles',
                                'Jueves',
                                'Viernes',
                                'Sabado'
                            ],
                            weekdaysAbbrev: [
                                'D',
                                'L',
                                'M',
                                'M',
                                'J',
                                'V',
                                'S'
                            ],
                            weekdaysShort: [
                                'Dom',
                                'Lun',
                                'Mar',
                                'Mier',
                                'Jue',
                                'Vier',
                                'Sab'
                            ]
                            },
                            isRTL: false,
                            maxDate: null,
                            minDate: null,
                            onClose: null,
                            onDraw: null,
                            onOpen: null,
                            onSelect: null,
                            parse: null,
                            setDefaultDate: false,
                            showClearBtn: false,
                            showDaysInNextAndPreviousMonths: false,
                            showMonthAfterYear: false,
                            yearRange: 10
                        }}
                        />
                </Col>
                <Col>
                    <Button
                    node="button"
                    waves="light"
                    onClick={() => {
                        props.onBuscar();
                    }} >Buscar</Button>
                </Col>
            </Row>
        </React.Fragment>)
}
export {ReporteFiltros as default}