import './category.css';

import { Card, CardTitle, Col, Row } from 'react-materialize';

import React from 'react';
import image from '../../../assets/images/imagenotavailable.png';

const CategoryView = (props) => {
  return (
    <React.Fragment>
      <Row xl={12} l={12} m={12} s={12}>
        {props.elements.length > 0 ? (
          props.elements.map((element, index) => {
            let description = element.nombre || element.modelo;
            let imgUrl = null;
            if (element.imageCloud) {
              imgUrl = element.imageCloud;
            } else {
              imgUrl = image;
            }
            //console.log(index, element, index+"_"+element.id);
            return description ? (
              <Col xl={2} l={4} m={4} s={12} key={index} offset="s4 m2">
                <Card
                  header={<CardTitle image={imgUrl} />}
                  onClick={(event) => props.onClickElement(element)}
                  className="card-category"
                >
                  <h6 className="center overflow-hidden">
                    {description.toUpperCase()}
                  </h6>
                </Card>
              </Col>
            ) : ( <Col xl={2} l={4} m={4} s={12} key={index} offset="s4 m2">
              <Card
                  header={<CardTitle image={image} />}
                  className="card-category"
                >
                  <h6 className="center overflow-hidden">
                    Sin Nombre {element.id}
                  </h6>
                </Card>
            </Col>);
          })
        ) : (
          <div className="center">
            <h4>No se encontraron artículos</h4>
          </div>
        )}
      </Row>
    </React.Fragment>
  );
};

export { CategoryView as default };
