import React from 'react';
import ReactDOM from 'react-dom';
import './modal.css';

function Modal(props) {

    if(!props.isOpen) {
        return null;
    }

    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <React.Fragment>
                    <div className="Modal">
                        <div className="Modal__container">
                            <button onClick={props.onClose} className="Modal__close-button"><i className="medium material-icons">close</i></button>
                            <br></br>
                            <br></br>
                            {props.children}
                        </div>
                    </div>
                </React.Fragment>,
                document.getElementById('modal')
            )}
        </React.Fragment>
    )

}
export {Modal as default}