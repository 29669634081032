import axios from 'axios';
import config from '../config.js';

class ExistenciaService {
  getExistencia = async (
    cveProducto,
    cseProducto,
    color,
    sucursal,
    new_med
  ) => {
    let data = {
      cve_prod: cveProducto,
      cse_prod: cseProducto,
      lugar: sucursal,
    };
    if (new_med) {
      data.new_med = new_med;
    }

    if (color !== 'SC') {
      data.color = color;
    }

    try {
      let response = await axios.post(`${config.apiUrl}existencia/`, { data });

      // removiendo espacios en propiedad lugar
      response.data.data.existencias = response.data.data.existencias.map(
        (response) => ({
          ...response,
          cse_prod: response.cse_prod.trim(' '),
          cve_prod: response.cve_prod.trim(' '),
          lugar: response.lugar.trim(' '),
        })
      );
      return response.data.data;
    } catch (error) {
      return { status: 200, existencias: 0 };
    }
  };
}

export { ExistenciaService as default };
