import { formatResponse, get, getApiEndpoint } from '../helpers/index.js';

class ColorService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'color';
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id },
    });
    const [brand] = formatResponse(getResponse);
    return brand;
  }
}

export { ColorService as default };
