import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class BrandService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'brand';
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', categoryId: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id, categoryId: '' },
    });
    const [brand] = formatResponse(getResponse);
    return brand;
  }

  /**
   * Returns brands related by passed category identifier
   * @param {Object} params
   * @param {string} params.categoryId
   * @return Promise<{Object[]}>
   */
  async relatedBy(params) {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const { categoryId = '' } = params;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', categoryId },
    });
    return formatResponse(getResponse);
  }
}

export { BrandService as default };
