import './modal-reporte.css';

import { Button, Col, DatePicker, Row, TextInput } from 'react-materialize';

import Select from 'react-select';
import React from 'react';
import AsyncSelect from 'react-select/async';


class ReportePedidos extends React.Component {

  customers = [];

  filterOptions = (inputValue) => {
    console.log(this.customers);
    //this.filtredCustomers = this.customers.slice(0, 100);
    this.filtredCustomers = this.customers.filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    return this.filtredCustomers;
  }

  promiseOptions = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterOptions(inputValue));
    });
  }

  loadFirst = () => {
    this.promiseOptions("");
  }

  render(){
    //console.log(this.props);
    this.customers = this.props.catalog.customers.map( (el)=> { return {"value": el.id, "label": el.name} } );
    let agents = this.props.catalog.agents.map( (el)=> { return {"value": el.id, "label": el.name} } );
    let numPedido = this.props.numPedido;
    return (
      <React.Fragment>
        <h4 className="m3">Reporte de pedidos</h4>
        <Row xl={12} l={12} m={12} s={12}>
          <Col xl={1} l={2} m={2} s={2}>
            <TextInput
              id="reportNumPedido"
              xl={12}
              l={12}
              m={12}
              s={12}
              placeholder="# pedido"
              value={numPedido || ''}
              onChange={(e) => {
                this.props.onChangeNumPedido(e.target.value);
              }}
            />
          </Col>
          <Col xl={2} l={2} m={2} s={2}>
            <AsyncSelect cacheOptions placeholder="Clientes" catalog={this.props.catalog} isClearable onChange={(value) => this.props.setSelectValue(value, "customer")} 
              defaultOptions={this.customers.slice(0,100)} loadOptions={this.promiseOptions}/>
          </Col>
          <Col xl={2} l={2} m={2} s={2}>
            <Select cacheOptions placeholder="Agentes" isClearable  options={agents}  onChange={(value) => this.props.setSelectValue(value, "agent")}/>
          </Col>
          <Col>
            <p>Fechas:</p>
          </Col>
          <Col xl={2} l={2} m={2} s={2}>
            <DatePicker
              id="fecha1"
              xl={12}
              l={12}
              m={12}
              s={12}
              options={{
                autoClose: true,
                container: null,
                defaultDate: null,
                disableDayFn: null,
                disableWeekends: false,
                events: [],
                firstDay: 0,
                format: 'dd-mm-yyyy',
                i18n: {
                  cancel: null,
                  clear: 'Clear',
                  done: null,
                  months: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre',
                  ],
                  monthsShort: [
                    'Ene',
                    'Feb',
                    'Mar',
                    'Abr',
                    'May',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dic',
                  ],
                  nextMonth: '›',
                  previousMonth: '‹',
                  weekdays: [
                    'Domingo',
                    'Lunes',
                    'Martes',
                    'Miercoles',
                    'Jueves',
                    'Viernes',
                    'Sabado',
                  ],
                  weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                  weekdaysShort: [
                    'Dom',
                    'Lun',
                    'Mar',
                    'Mier',
                    'Jue',
                    'Vier',
                    'Sab',
                  ],
                },
                isRTL: false,
                maxDate: null,
                minDate: null,
                onClose: null,
                onDraw: null,
                onOpen: null,
                onSelect: null,
                parse: null,
                setDefaultDate: false,
                showClearBtn: false,
                showDaysInNextAndPreviousMonths: false,
                showMonthAfterYear: false,
                yearRange: 10,
              }}
            />
          </Col>
          <Col>
            <p>a</p>
          </Col>
          <Col xl={2} l={2} m={2} s={2}>
            <DatePicker
              id="fecha2"
              xl={12}
              l={12}
              m={12}
              s={12}
              options={{
                autoClose: true,
                container: null,
                defaultDate: null,
                disableDayFn: null,
                disableWeekends: false,
                events: [],
                firstDay: 0,
                format: 'dd-mm-yyyy',
                i18n: {
                  cancel: null,
                  clear: 'Clear',
                  done: null,
                  months: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre',
                  ],
                  monthsShort: [
                    'Ene',
                    'Feb',
                    'Mar',
                    'Abr',
                    'May',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dic',
                  ],
                  nextMonth: '›',
                  previousMonth: '‹',
                  weekdays: [
                    'Domingo',
                    'Lunes',
                    'Martes',
                    'Miercoles',
                    'Jueves',
                    'Viernes',
                    'Sabado',
                  ],
                  weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                  weekdaysShort: [
                    'Dom',
                    'Lun',
                    'Mar',
                    'Mier',
                    'Jue',
                    'Vier',
                    'Sab',
                  ],
                },
                isRTL: false,
                maxDate: null,
                minDate: null,
                onClose: null,
                onDraw: null,
                onOpen: null,
                onSelect: null,
                parse: null,
                setDefaultDate: false,
                showClearBtn: false,
                showDaysInNextAndPreviousMonths: false,
                showMonthAfterYear: false,
                yearRange: 10,
              }}
            />
          </Col>
          <Col>
            <Button
              node="button"
              waves="light"
              onClick={() => {
                this.props.onBuscar();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export { ReportePedidos as default };
