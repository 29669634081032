import { formatResponse, get, post, put, getApiEndpoint } from '../helpers/index.js';

class SubcategoryService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'subCategory';
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async create(data) {
    const action = 'create';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const postResponse = await post(this.client, endpoint, formattedData);
    const [subcategoryCreated] = formatResponse(postResponse);
    return subcategoryCreated;
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id },
    });
    const [subcategory] = formatResponse(getResponse);
    return subcategory;
  }

  async update(id, data) {
    const action = 'update';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const putResponse = await put(this.client, endpoint, formattedData, {
      params: { id },
    });
    const [subcategoryUpdated] = formatResponse(putResponse);
    return subcategoryUpdated;
  }
}

export { SubcategoryService as default };
