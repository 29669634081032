import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class PantoneService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'pantone';
  }

  async all() {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    // TODO: This service not filter by id
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id },
    });
    const [pantone] = formatResponse(getResponse);
    return pantone;
  }
}

export { PantoneService as default };
