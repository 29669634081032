import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class StyleService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'style';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', categoryId: '', brandId: '', genderId: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id, categoryId: '', brandId: '', genderId: '' },
    });
    const [style] = formatResponse(getResponse);
    return style;
  }

  /**
   * Returns styles related by passed category, brand and gender identifier.
   * @param {Object} params
   * @param {string} params.brandId
   * @param {string} params.categoryId
   * @param {string} params.genderId
   * @return {Object[]}
   */
  async relatedBy(params) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const { categoryId = '', brandId = '', genderId = '' } = params;
    console.log(params);
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', categoryId, brandId, genderId },
    });
    return formatResponse(getResponse);
  }
}

export { StyleService as default };
