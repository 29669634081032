import 'dotenv/config';

const {
  REACT_APP_API_URL,
  REACT_APP_FILES_URL,
  REACT_APP_FILES_URL_ESPECIALES,
} = process.env;

let config = {
  apiUrl: REACT_APP_API_URL,
  filesUrl: REACT_APP_FILES_URL,
  filesUrlEspeciales: REACT_APP_FILES_URL_ESPECIALES,
};

export { config as default };
