import React from 'react'

import NavigationItemView from './navigation-item.view'

const NavigationView = (props) => {
    return (
        <div className='breadcrum'>
            <ul className='nav nav-tabs process-model more-icon-preocess' role='tablist'>
                {
                    props.navigations.map((item, index) => {
                        return (
                                <NavigationItemView 
                                    {...item}
                                    familyId={props.familyId}
                                    brandId={props.brandId}
                                    genderId={props.genderId}
                                    styleId={props.styleId}
                                    modelId={props.modelId}
                                    showPedido={props.showPedido}
                                    brandsClass={props.brandsClass}
                                    gendersClass={props.gendersClass}
                                    stylesClass={props.stylesClass}
                                    modelsClass={props.modelsClass}
                                    productsClass={props.productsClass}
                                    brandsName={props.brandsName}
                                    gendersName={props.gendersName}
                                    stylesName={props.stylesName}
                                    modelsName={props.modelsName}
                                    productsName={props.productsName}
                                    hiddenPedido={props.hiddenPedido} 
                                    getCategoryData={props.getCategoryData} 
                                    key={index}/>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export { NavigationView as default }
