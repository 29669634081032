import React from 'react';
import Modal from '../modals/modal';
import ReportePedidos from './modal-reporte';
import Loader from '../loader/loader.view';
import GridReporte from './grid-reporte';
import Swal from 'sweetalert2';
import PedidoReprintView from './pedido-reprint';
import printJS from 'print-js';
import { ErpConnect as qad } from '../../../services';

class ModalReporte extends React.Component {
  state = {
    loading: true,
    fecha1: null,
    fecha2: null,
    numPedido: null,
    dataGrid: null,
    loadingGrid: false,
    reportePrint: false,
    propsPrint: {},
    busquedaFiltros: false, // valida mensaje en toolbar
  };

  setSelectValue = (value, category) => {
    let currentState = {};
    currentState[category] = value;
    this.setState(currentState);
  };

  async componentDidMount() {
    try {
      this.setState({
        mounted: true,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleClose = () => {
    this.setState({
      fecha1: null,
      fecha2: null,
      numPedido: null,
      dataGrid: null,
    });
    this.props.onClose();
  };

  handleChangeNumPedido = (value) => {
    this.setState({
      numPedido: value,
    });
  };

  formatDatosGridPedidos = (resultPedidos) => {
    return resultPedidos;
  };

  handleBuscar = async () => {
    // ejecucion de busqueda
    this.setState({
      loadingGrid: true,
    });
    try {
      await this.getFechas();
      if ( this.evaluarFechas() ) {
        const query = {
          salesOrderId: this.state.numPedido,
          customerId: this.state.customer?.value,
          salesPersonId: this.state.agent?.value,
          fromDate: this.state.fecha1,
          toDate: this.state.fecha2
        };
        console.log(query, this.state);
        let datosGrid = await qad.catalog.salesOrder.all(query);
        datosGrid = this.formatDatosGridPedidos(datosGrid);
        this.buildDatosGrid(datosGrid);
      } else {
        Swal.fire({
          icon: 'warning',
          text: 'Favor de colocar un rango valido de fechas.',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        loadingGrid: false,
      });
    }
  };

  async buildDatosGrid(datos) {
    this.setState({
      dataGrid: datos,
      loading: false,
      busquedaFiltros:
        this.state.numPedido ||
        this.state.client?.id ||
        this.state.agent?.id ||
        this.state.fecha1 ||
        this.state.fecha2
          ? 1
          : 0,
    });
  }

  getFechas() {
    let promesa = new Promise((resolve, reject) => {
      return resolve(
        this.setState({
          fecha1: document.getElementById('fecha1').value
            ? document.getElementById('fecha1').value
            : null,
          fecha2: document.getElementById('fecha2').value
            ? document.getElementById('fecha2').value
            : null,
        })
      );
    });
    return promesa;
  }

  evaluarFechas() {
    let _eval = true;
    if (!this.state.fecha1 && this.state.fecha2) {
      _eval = false;
    }
    return _eval;
  }

  handlePrint = (
    numeroPedido,
    remision,
    cliente,
    vendedor,
    comentarios,
    direccion,
    fecha,
    status,
    subtotal,
    descuento,
    iva,
    total,
    articulos,
    usuario
  ) => {
    this.setState(
      {
        reportePrint: true,
        propsPrint: {
          numeroPedido: numeroPedido,
          remision: remision,
          cliente: cliente,
          vendedor: vendedor,
          comentarios: comentarios,
          direccion: direccion,
          fecha: fecha,
          status: status,
          subtotal: subtotal,
          descuento: descuento,
          iva: iva,
          total: total,
          articulos: articulos,
          usuario: usuario
        },
      },
      async function () {
        printJS({
          printable: 'reprintContainer',
          type: 'html',
          targetStyles: ['*'],
        });

        this.setState({
          reportePrint: false,
        });
      }
    );
  };

  render() {
    if (!this.state.loading) {
      if (this.state.reportePrint) {
        return (
          <React.Fragment>
            <Modal
              isOpen={this.props.modalReporteIsOpen}
              onClose={() => {
                this.handleClose();
              }}
            >
              <PedidoReprintView onProps={this.state.propsPrint} />
            </Modal>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Modal
              isOpen={this.props.modalReporteIsOpen}
              onClose={() => {
                this.handleClose();
              }}
            >
              <ReportePedidos
                onChangeFecha1={this.handleChangeFecha1}
                onChangeFecha2={this.handleChangeFecha2}
                catalog={this.props.catalog}
                numPedido={this.state.numPedido}
                onChangeNumPedido={this.handleChangeNumPedido}
                setSelectValue={this.setSelectValue}
                onBuscar={this.handleBuscar}
              />
              <br />
              {this.state.loadingGrid ? (
                <Loader />
              ) : (
                this.state.dataGrid && (
                  <GridReporte
                    catalog={this.props.catalog}
                    data={this.state.dataGrid}
                    onPrint={this.handlePrint}
                    onBuscar={this.handleBuscar}
                    filtros={this.state.busquedaFiltros}
                  />
                )
              )}
            </Modal>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <Loader />
        </React.Fragment>
      );
    }
  }
}
export default ModalReporte;
