import React from 'react'

const NavigationItemView = (props) => {
    return (
        <li role='presentation' className={props.class === 'active' ? props.class : props[props.class]} onClick={event => {
            let classNameSibling = event.target.parentNode.parentNode.nextElementSibling

            if (props.showPedido) {
                props.hiddenPedido()
            }


            if (classNameSibling) {
                if ((props.class === 'active' || props[props.class] === 'active') && classNameSibling.className) {
                    (props.id) ? props.getCategoryData(props.category, props.id) : props.getCategoryData(props.category, props[props.idAttr])
                    
                    return true
                }
            }

        }}>
            <a href={props.href} aria-controls={props.ariaControl} role='tab' data-toggle='tab'>
                <i className={props.icon} aria-hidden='true'></i>
                <p>{props.text}</p>
            </a>
        </li>
    )
}

export { NavigationItemView as default }
