class NavigationService {
  async getNavigations () {
    return [
        {
            class: 'active',
            id: 0,
            idAttr: '',
            href: '#Familias',
            ariaControl: 'Familias',
            text: 'Familias',
            category: 'familia',
            nextCategory: 'marca',
            icon: 'fa fa-users'
        },
        {
            class: 'brandsClass',
            idAttr: 'familyId',
            href: '#Marcas',
            ariaControl: 'Marcas',
            text: 'Marcas',
            category: 'marca',
            nextCategory: 'genero',
            icon: 'fa fa-tags'
        },
        {
            class: 'gendersClass',
            idAttr: 'brandId',
            href: '#Generos',
            ariaControl: 'Generos',
            text: 'Generos',
            category: 'genero',
            nextCategory: 'estilo',
            icon: 'fa fa-venus-mars'
        },
        {
            class: 'stylesClass',
            idAttr: 'genderId',
            href: '#Estilos',
            ariaControl: 'Estilos',
            text: 'Estilos',
            category: 'estilo',
            nextCategory: 'modelo',
            icon: 'fa fa-magic'
        },
        {
            class: 'modelsClass',
            idAttr: 'styleId',
            href: '#Modelos',
            ariaControl: 'Modelos',
            text: 'Modelos',
            category: 'modelo',
            nextCategory: 'producto',
            icon: 'fa fa-gem'
        },
        {
            class: 'productsClass',
            idAttr: '',
            href: '#Producto',
            ariaControl: 'Producto',
            text: 'Producto',
            category: 'producto',
            nextCategory: null,
            icon: 'fa fa-tshirt'
        }
    ]
  }
}

export { NavigationService as default }
