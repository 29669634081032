import axios from 'axios'

class FileService {
    async checkFile (url) {
        let response;
        try {
            response = await axios.get(url)
        } catch (error) {
            response = {status: -1}
        }
        

        return response
    }

}

export { FileService as default }
