import React from 'react';

import LoginContainer from './login.container';
import AppContainer from './app.container';

class IndexContainer extends React.Component {
  state = {
    login: false,
  };

  componentDidMount() {
    let formattedCategories = JSON.parse(
      localStorage.getItem('formattedCategories')
    );
    let sesion = formattedCategories ? this.setState({ login: true }) : false;

    return sesion;
  }

  render() {
    return this.state.login ? (
      <AppContainer logout={this.logout} />
    ) : (
      <div>
        <LoginContainer login={this.login} />
      </div>
    );
  }

  login = () => {
    this.setState({ login: true });
  };

  logout = () => {
    this.setState({ login: false });
  };
}

export { IndexContainer as default };
