import './modal-add-pedidos.css';
import { Button, Row, Col, Card, CardTitle, Tabs, Tab } from 'react-materialize';
import LoadingView from '../loading/loading.view';
import React from 'react';
import image from '../../../assets/images/imagenotavailable.png';

function ModalPedidoAdd(props) {
  let items = props.newArticle;
  return (
    <React.Fragment>
      {
        items.map((item, index) => {
          return (
            <Tabs
              key={index}
              className="tab-demo z-depth-1"
              defaultValue={item.items.length.toString()}
              onChange={(event) => props.selectCategory( item.availableKeys[parseInt(event.slice(-1))], parseInt(event.slice(-1)), null)}
            >
            {
              item.availableKeys.map( (category, idx)=>{
                return (
                    <Tab
                      className="blue"
                      options={{
                        duration: 50,
                        onShow: null,
                        responsiveThreshold: Infinity
                      }}
                      title={category.text + props.getCategoryLevelName( idx )}
                      key={idx}
                      header={category.text + props.getCategoryLevelName( idx )}
                      active={item.items.length === idx}
                      disabled={item.items.length < idx}
                      node="div"
                      style={ {backgroundColor: 'white', color: 'black'} }>
                        {
                          (props.isLoadingCategory)
                          ?
                            <LoadingView key={"LoadingView"+idx} />
                          :
                          (
                            <Row key={"Row_"+idx} xl={12} className="tab-container">
                              {
                                props.getCategoryLevelElements( category, idx ).map( (element) => {
                                    let imgUrl = null;
                                    if (element.imageCloud)
                                    {
                                      imgUrl = element.imageCloud;
                                    } else {
                                      imgUrl = image;
                                    }
                                    if ( category.category !== "producto" )
                                    {
                                      return element.name 
                                        ? 
                                        (
                                          <Col xl={2} l={4} m={4} s={12} key={element.id} offset="s4 m2">
                                            <Card
                                              header={
                                                <img style={{objectFit: 'scale-down'}} data-src={element.imageCloud}  src={imgUrl} onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.onError = null;
                                                  currentTarget.src = image;
                                                }}/>
                                              }
                                              onClick={(event) => props.selectCategory( category, idx, element)}
                                              className="card-category"
                                            >
                                              <h6 className="center overflow-hidden">
                                                {element.name.toUpperCase()}
                                              </h6>
                                            </Card>
                                          </Col>
                                        )
                                        : 
                                        (
                                          <Col xl={2} l={4} m={4} s={12} key={element.id} offset="s4 m2">
                                            <Card
                                                header={<CardTitle image={image} />}
                                                className="card-category"
                                              >
                                                <h6 className="center overflow-hidden">
                                                  Sin Nombre {element.id}
                                                </h6>
                                              </Card>
                                          </Col>
                                        )
                                    }
                                    else
                                    {
                                      return (
                                        <Col s={12} key={"Product_"+element.id}>
                                          <Row s={6} key={"Product_row_"+element.id}>
                                            <Col s={6} key={"image_"+element.id}>
                                              <img style={{height: '100%', width: '100%',objectFit: 'contain'}} data-src={element.imageCloud} src={element.imageCloud} onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.onError = null;
                                                  currentTarget.src = image;
                                                }}/>
                                            </Col>
                                            <Col s={6} key={"sizes_"+element.id}>
                                              <Row s={12} key={"size_element_header"}>
                                                <Col s={2} key={"sizes_header"} >
                                                  Talla
                                                </Col>
                                                <Col s={1} key={"sizes_mty_header"}>
                                                  MTY
                                                </Col>
                                                <Col s={1} key={"sizes_mex_header"}>
                                                  MEX
                                                </Col>
                                                <Col s={1} key={"sizes_alma_header"}>
                                                  Almacenes
                                                </Col>
                                                <Col s={1} key={"sizes_xlleg_header"}>
                                                  XLlegar
                                                </Col>
                                                <Col s={3} key={"sizes_cant_header"}>
                                                  Cantidad
                                                </Col>
                                                <Col s={3} key={"sizes_subt_header"}>
                                                  Sub-total
                                                </Col>
                                              </Row>
                                              {
                                                element.sizeOptions.map( (size) => {
                                                  return (<Row s={12} key={"size_element_"+size.id}>
                                                    <Col s={2} key={"sizes_"+size.id} >
                                                      {size.label}
                                                    </Col>
                                                    <Col s={1} key={"sizes_mty_"+size.id} >
                                                      {}
                                                    </Col>
                                                    <Col s={1} key={"sizes_mex_"+size.id} >
                                                      {0}
                                                    </Col>
                                                    <Col s={1} key={"sizes_alma_"+size.id} >
                                                      {}
                                                    </Col>
                                                    <Col s={1} key={"sizes_xlleg_"+size.id} >
                                                      {0}
                                                    </Col>
                                                    <Col s={3} key={"sizes_cant_"+size.id} >
                                                      {0}
                                                    </Col>
                                                    <Col s={3} key={"sizes_subt_"+size.id} >
                                                      {0}
                                                    </Col>
                                                  </Row>)
                                                })
                                              }
                                              <Row s={12} key={"size_element_colors"}>
                                                {
                                                  element.colorsOptions.map( (color) => {
                                                    let currentBackgroundColor = '#FFF';
                                                    if ( color.hasColor )
                                                    {
                                                      if(color.colors.length > 1)
                                                      {
                                                        let proportion = 100 / color.colors.length;
                                                        /*linear-gradient(
                                                          120deg,
                                                          pink 0%,
                                                          pink 30%,
                                                          red 30%,
                                                          red 50%,
                                                          paleturquoise 50%,
                                                          paleturquoise 100%
                                                        );*/
                                                        currentBackgroundColor = "linear-gradient(120deg"
                                                        let proportionActual = 0;
                                                        color.colors.each(( hexa, idx ) => {
                                                          currentBackgroundColor += "," + hexa + " " + proportionActual;
                                                          proportionActual += proportion;
                                                          currentBackgroundColor += ","+ hexa + " " + proportionActual;
                                                        });

                                                        currentBackgroundColor = ");"
                                                      }
                                                      else
                                                      {
                                                        currentBackgroundColor = color.colors[0];
                                                      }
                                                    }
                                                    return (<Col s={1} key={"colors_"+color.id}>
                                                      <div style={{width:"2em",width:"2em", background: currentBackgroundColor, borderRadius: "50%"}} ></div>
                                                      {color.label}
                                                    </Col>)
                                                  })
                                                }
                                              </Row>
                                            </Col>
                                            <Col s={10} key={"add_"+element.id} offset="s1">
                                              <Button >Agregar y Continuar</Button>
                                              <Button >Agregar y Cerrar</Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                      )
                                    }
                                })
                              }
                            </Row>
                          )
                        }
                    </Tab>
                )
              })
            }
          </Tabs>)
        })
      }
    </React.Fragment>
  );
}
export { ModalPedidoAdd as default };
