import { Button, Col, Container, Row, TextInput } from 'react-materialize';
import './modalListArticulos.css';

import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';
import AsyncSelect from 'react-select/async';

// servicios

class ModalExistences extends React.Component {
  state = {
    loading: false,
    productId: null,
    product: null,
    existences: null
  };

  async componentDidMount() {
  }

  handleSearch = async () => {
    this.setState({loading:true});
    console.log("changed", this.state);
    if ( this.state.productId )
    {
      try {
        const product = await qad.catalog.product.findById(this.state.productId);
        const existences = await qad.catalog.inventory.findById(this.state.productId);
        this.setState({
          product,
          existences,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          productId: null,
        });
      } finally {
        this.setState({
          loading: false,
        });
      }
    }
    else
    {
      this.setState({loading:false});
      Swal.fire({
        title: 'No se especifico un Producto intente nuevamente'
      });
      console.log("error", this.state);
    }
  };

  handleCloseModal = () => {
    this.setState({
      productId: null,
    });
    this.props.onClose();
  };
  // muestra remisión
  showExistences = () => {
    if (this.state.product && this.state.existences) {
      return this.state.existences.map( (existence) => {
        return (
          <div  key={existence.location} className="card-stock">
            <h3>{ existence.location }</h3>
            <h4>{ existence.stock }</h4>
          </div>
        );
      });
    }
    else
    {
      return null;
    }
  };

  filterOptions = (inputValue, slice = 0) => {
    if ( slice > 0 )
    {
      this.filtred = this.products.slice(0, slice).filter((i) => {
        return i.value.toLowerCase().includes(inputValue.toLowerCase())
          || i.model.toLowerCase().includes(inputValue.toLowerCase())
          || i.color.toLowerCase().includes(inputValue.toLowerCase())
          || i.brand.toLowerCase().includes(inputValue.toLowerCase())
          || i.code.toLowerCase().includes(inputValue.toLowerCase())
      });
    }
    else
    {
      this.filtred = this.products.filter((i) => {
          return i.value.toLowerCase().includes(inputValue.toLowerCase())
            || i.model.toLowerCase().includes(inputValue.toLowerCase())
            || i.color.toLowerCase().includes(inputValue.toLowerCase())
            || i.brand.toLowerCase().includes(inputValue.toLowerCase())
            || i.code.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    console.log(this.filtred);
    return this.filtred;
  }

  promiseOptions = (inputValue) => {
    if (inputValue.length > 3 )
    {
      return new Promise ((resolve) => {
        resolve(this.filterOptions(inputValue));
      });
    }
    else
    {
      return new Promise(resolve => {
        resolve(this.filterOptions(inputValue, 500))
      })
    }
  }

  setSelectValue = ( product ) => {
    if (product)
    {
      let currentState = {};
      this.setState({ loading: true, productId: product.value });
      setTimeout(() => {
        this.handleSearch();
      }, 200);
    }
  };

  render() {
    this.products = this.props.catalog.products.map( (el)=> {
      return {
        value: el.id, 
        label: el.article + " - " + el.longDescription + "( "+el.colorLabel+" )",
        model: el.modelName, 
        color: el.colorLabel, 
        brand: el.brandName, 
        code: el.article
      }
    });
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.handleCloseModal}
        >
          <Container>
            <Row>
              <Col s={12} m={12} l={12} xl={12}>
                <h3 className="m3">Existencias</h3>
                <AsyncSelect cacheOptions placeholder="Productos" isClearable onChange={(value) => {this.setSelectValue(value)}} 
                  defaultOptions={this.products.slice(0,100)} loadOptions={this.promiseOptions}/>
              </Col>
            </Row>
            <br></br>
            <br></br>
            {
              this.state.loading
                ? <LoadingView />   
                :
                  <div>
                    {
                      ( this.state.product !== null ) 
                      ?
                        [
                          (
                            <div className="product-container">
                              <div>
                                <label>Producto:</label>
                                <span>{this.state.product.longDescription}</span>
                              </div>
                              <div>
                                <label>Familia:</label>
                                <span>{this.state.product.categoryId}</span>
                              </div>
                              <div>
                                <label>Marca:</label>
                                <span>{this.state.product.brandName}</span>
                              </div>
                              <div>
                                <label>Genero:</label>
                                <span>{this.state.product.genderName}</span>
                              </div>
                              <div>
                                <label>Talla:</label>
                                <span>{this.state.product.sizeName}</span>
                              </div>
                            </div>
                          )
                          ,
                          (
                            <div className="product-container">  
                              <div>
                                <label>Color:</label>
                                <span>{this.state.product.colorLabel}</span>
                              </div>
                              <div>
                                <label>Estilo:</label>
                                <span>{this.state.product.styleName}</span>
                              </div>
                              <div>
                                <label>Modelo:</label>
                                <span>{this.state.product.modelName}</span>
                              </div>
                              <div>
                                <label>Código:</label>
                                <span>{this.state.product.article}</span>
                              </div>
                            </div>
                          )
                        ]
                      :
                      null
                    }
                  <div className="card-stock-container">
                    {this.showExistences()}
                  </div>
                </div>
            }
          </Container>
        </Modal>
      </React.Fragment>
    );
  }
}
export { ModalExistences as default };
