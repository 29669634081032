import 'dotenv/config';

const {
  REACT_APP_ERP_API_BASE_URI,
  REACT_APP_WEBSITE,
} = process.env;

let config = {
  apiBaseUrl: REACT_APP_ERP_API_BASE_URI,
  useAlternativeUrl: false,
  websiteUrl: REACT_APP_WEBSITE
};

export { config as default };
