import './modal-reporte.css';
import { Button, Col, Row } from 'react-materialize';
import React from 'react';
import Select from 'react-select';

function ReporteProducts(props) {
  let categories = props.catalog.categories.map( (el)=> { return { "value": el.id, "label": el.name } } );
  let brands = props.catalog.brands.map( (el)=> { return {"value": el.id, "label": el.name} } );
  let genders = props.catalog.genders.map( (el)=> { return {"value": el.id, "label": el.name} } );
  let styles = props.catalog.styles.map( (el)=> { return {"value": el.id, "label": el.name} } );
  let patterns = props.catalog.patterns.map( (el)=> { return {"value": el.id, "label": el.name} } );

  return (
    <React.Fragment>
      <h4 className="m3">Reporte de articulos</h4>
      <Row xl={12} l={12} m={12} s={12}>
        <Col xl={2} l={2} m={2} s={2}>
          <Select placeholder="Familia" isClearable options={categories} onChange={(value) => props.setSelectValue(value, "category")}/>
        </Col>
        <Col xl={2} l={2} m={2} s={2}>
          <Select placeholder="Marcas" isClearable options={brands} onChange={(value) => props.setSelectValue(value, "brand")}/>
        </Col>
        <Col xl={2} l={2} m={2} s={2}>
          <Select placeholder="Generos" isClearable options={genders} onChange={(value) => props.setSelectValue(value, "gender")}/>  
        </Col>
        <Col  xl={2} l={2} m={2} s={2}>
          <Select placeholder="Estilos" isClearable options={styles} onChange={(value) => props.setSelectValue(value, "style")}/>
        </Col>
        <Col  xl={2} l={2} m={2} s={2}>
          <Select placeholder="Modelos" isClearable options={patterns} onChange={(value) => props.setSelectValue(value, "pattern")}/>
        </Col>
        <Col>
          <Button
            node="button"
            waves="light"
            onClick={() => {
              props.onBuscar();
            }}
          >
            Buscar
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}
export { ReporteProducts as default };
