import axios from 'axios';
import config from '../config.js';

class ReporteService {
  getTableData = async (nameTable, value, id) => {
    let result = null;
    try {
      result = await axios.get(
        `${config.apiUrl}tableData/reporte?nameTable=${nameTable}&value=${value}&id=${id}`
      );
      result = result.data.result;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`An error has ocurred: ${e}`);
      result = [];
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return result;
    }
  };
  getReportePedidos = async (numPedido, cliente, agente, fecha1, fecha2) => {
    let result = null;
    try {
      result = await axios.get(
        `${config.apiUrl}reporte/pedidos?numPedido=${numPedido}&cliente=${cliente}&agente=${agente}&fecha1=${fecha1}&fecha2=${fecha2}`
      );
      let estatus = result.data.status;
      if (estatus === 200) {
        result = result.data.result;
      } else {
        result = [];
      }
    } catch (e) {
      result = [];
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return result;
    }
  };
  getReporteCanalAgentaClase = async (fecha1, fecha2) => {
    let result = null;
    try {
      result = await axios.get(
        `${config.apiUrl}reporte/pedidoscac?fecha1=${fecha1}&fecha2=${fecha2}`
      );
      let estatus = result.data.status;
      if (estatus === 200) {
        result = result.data.result;
      } else {
        result = [];
      }
    } catch (e) {
      result = [];
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return result;
    }
  };
  getDetalleReporte = async (numPedido) => {
    let result = null;
    try {
      result = await axios.get(
        `${config.apiUrl}reporte/detalle?numPedido=${numPedido}`
      );
      if (result.data.status === 200) {
        result = result.data.result;
      } else {
        result = [];
      }
    } catch (e) {
      result = [];
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return result;
    }
  };
}
export { ReporteService as default };
