import React from 'react';
import { Button, Col } from 'react-materialize';
import './product.css';

function ProductosRealacionadosView(props) {
  let items = props.items;
  // usar cse_prod para filtar los elementos.. opcional
  if (items) {
    return (
      <React.Fragment>
        <div xl={12} l={12} m={12} s={12}>
          {items.map((element) => {
            return (
              <React.Fragment key={element.modelo}>
                <Col l={6} m={12} style={{ padding: '3px' }}>
                  <Button
                    small={true}
                    style={{
                      backgroundColor: '#00aaf6',
                      minHeight: '40px',
                      fontSize: '9pt',
                      lineHeight: '1',
                    }}
                    className="btnPRelacionados"
                    xl={12}
                    l={12}
                    m={12}
                    s={12}
                    align="center"
                    node="button"
                    waves="light"
                    onClick={async () => {
                      element.tipo = 'modelo';
                      await props.changeProductViewIds(
                        element.id_categoria,
                        element.nombre_categoria,
                        element.id_marca,
                        element.nombre_marca,
                        element.id_genero,
                        element.nombre_genero,
                        element.id_estilo,
                        element.nombre_estilo,
                        element.modelo
                      );
                      props.onClickElement(element);
                      let arrayRute = props.imageRute.split('/');
                      console.log("ProdRel",element);
                      props.changeImageRute(
                        element.nombre_categoria,
                        element.nombre_marca,
                        element.nombre_genero,
                        element.nombre_modelo,
                        arrayRute[arrayRute.length - 2]
                      );
                    }}
                  >
                    {element.nombre_modelo}
                  </Button>
                </Col>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
export default ProductosRealacionadosView;
