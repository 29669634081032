import { formatResponse, get, getApiEndpoint } from '../helpers/index.js';

class InventoryService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'inventories';
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id,
      },
    });
    return formatResponse(getResponse);
  }
}

export { InventoryService as default };
